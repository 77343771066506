import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import StockLog from '../../components/admin/StockLog'
const addtostock = () =>{
    return (
        <div className="w-full h-screen">
        <AdminNav />
            <StockLog />
        </div>
    )
}

export default addtostock
