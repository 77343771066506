import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import SetOrderDetail from '../../components/admin/SetOrderDetail'

const updateOrderInfo = () =>{
  return (
    <div className='w-full h-auto flex flex-col'>
        <AdminNav />
        <SetOrderDetail />
    </div>
  )
}

export default updateOrderInfo