import React, {useEffect, useState, useRef, Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Link } from 'react-router-dom';
import axios from 'axios';


const ListOfItems = () =>{
    const [products, setProducts] = useState([])
    const [inventory, setInventory] = useState([])
    const [data, setData] = useState([])
    const [p, setP] = useState([])
    const [search, setSearch] = useState()
    const brand_ref = useRef()

    let total = 0

    const setTotal = (a,b) =>{
      total = total + (a*b)
    } 
    //const br = useRef(null)
    const urlAPI = `${process.env.REACT_APP_url}admin/items`
    const urlAPI_INVENTORY = `${process.env.REACT_APP_url}product/inventory`
      useEffect(() => {
            axios.get(urlAPI).then(res => { 
            setProducts(res.data)
            //setLoad(true)
            
            }).catch(err => {
            console.log(err)
            })
        }, [])

       /*
        useEffect(() => {
          axios.get(urlAPI_INVENTORY).then(res => { 
          setInventory(res.data)
          //setLoad(true)
          
          }).catch(err => {
          console.log(err)
          })
      }, [])
        */
      //console.log(inventory[0].tis)
        const refreshPage = ()=>{
          window.location.reload();
       }

        const deleteItem = (id) => {
          const urlAPIDELETE = `${process.env.REACT_APP_url}/product/delete.php?id=${id}`
          axios.get(urlAPIDELETE).then(res => { 
              refreshPage()
            }).catch(err => {
            console.log(err)
            })
        } 

        const addToSlider = (id) =>{
          const urlAPISlide = `${process.env.REACT_APP_url}/slider/addslide.php?id=${id}`
          axios.get(urlAPISlide).then(res => { 
              refreshPage()
            }).catch(err => {
            console.log(err)
            })
        }
        
        const resetTable = () => {
          products.length && products.map((p, index) =>
            {
              let tr = "tr"+index
              let row = document.getElementById(tr)
              row.classList = ''
              //row.classList.add("h-12 border-b border-gray-300 py-2") 
            }
          )
        }
        const searchBrand = (sb) =>{
              let x = "h-12 border-b border-gray-300 py-2"
             if(sb == ""){
                resetTable()
             }else{
                products.length && products.map((p, index) =>
                  { 
                    let tr = "tr"+index
                    let row = document.getElementById(tr) 
                    let brand = p.brand 
                    row.classList = ''
                    if(brand.toLowerCase().includes(sb.toLowerCase())){
                     
                      row.classList.add("bg-green-300")
                    }else{
                      
                      row.classList.add("hidden")
                    }
                  }
                )
             }
          }


          const searchItem = (sb) =>{

            let x = "h-12 border-b border-gray-300 py-2"
           if(sb == ""){
              resetTable()
           }else{
              products.length && products.map((p, index) =>
                { 
                  let tr = "tr"+index
                  let row = document.getElementById(tr) 
                  let item = p.ref //produit
                  //console.log(p)
                  row.classList = ''
                  if(item.toLowerCase().includes(sb.toLowerCase())){
                    
                    row.classList.add("bg-green-300")
                  }else{
                    
                    row.classList.add("hidden")
                  }
                }
              )
           }
        }

     

    return (
        <>



        <div className="w-full h-screen flex flex-col px-4 overflow-y-scroll ">

            <div className="w-full h-auto py-2 px-12 flex flex-row bg-green-400">
             {
               products.length && products.map((product, index)=>
               setTotal(product.price, product.instock)
               )
              
             }
              <div className="w-auto h-auto px-4 py-4 flex flex-col items-center justify-center rounded-md bg-white mr-2">
                <span className="text-sm font-bold uppercase">total en stock :</span>  
                <span className="text-md font-bold uppercase">{total.toLocaleString()} - DA</span>
              </div>
              <div className="w-auto h-auto px-4 py-4 flex flex-col items-center justify-center rounded-md bg-white">
                <span className="text-sm font-bold uppercase">export excel :</span>  
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button bg-red-400 px-2 rounded-md border-red-500 border-2"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="EXPORT XLS"/>
              </div>
            </div>
          
            <table className="table-fixed" id="table-to-xls">
                  <thead className="bg-yellow-400">
                    <tr className="text-sm text-left h-12 border-b border-gray-700 bg-gray-200 px-2" >
                      <th className="text-center">ID</th>
                      <th className="text-center">Image</th>
                      <th className="text-center">marque</th>
                      <th className="text-center">article</th>
                      <th className="text-center">ref</th>
                      <th className="text-center">prix</th>
                      <th className="text-center">volume</th>
                      <th className="text-center">Visite</th>
                      <th className="text-center">etat</th>
                      <th className="text-center">stock</th>
                      <th className="text-center">vendu</th>
                      <th className="text-center">actions</th>
                    </tr>
                    <tr className="text-sm text-left h-12 border-b border-gray-700 bg-gray-700 px-2" >
                      <th>ID</th>
                      <th className="text-center">Image</th>
                      <th className="py-2"><input type="text" className="w-full h-8 pl-2" placeholder="search" ref={brand_ref} id="brand" 
                                            onKeyUp={(e)=>searchBrand(e.target.value)}></input></th>
                      <th className="py-2"><input type="text" className="w-full h-8 pl-2" placeholder="search"  id="item" 
                                            onKeyUp={(e)=>searchItem(e.target.value)}></input></th>
                      <th className="text-center">ref</th>
                      <th className="text-center">prix</th>
                      <th className="text-center">volume</th>
                      <th className="text-center">Visite</th>
                      <th className="text-center">etat</th>
                      <th className="text-center">stock</th>
                      <th className="text-center">vendu</th>
                      <th className="text-center">actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      products.length && products.map((product, index)=>
                          
                            
                          <tr className="h-12 border-b border-gray-300 py-2 hover:bg-gray-200" 
                              id={`tr${index}`} 
                              style={{"background": product.instock < 0 ? "#f00" : "#fff" }}>
                            <td>{product.id}</td>
                            <td>
                              <div className="w-12 h-12 rounded-md bg-contain bg-center bg-no-repeat my-2" style={{backgroundImage:`url(${process.env.REACT_APP_url}/product/images/${product.thumb})`}}></div>
                            </td>
                            <td className="flex flex-row ">
                              <div className="w-8 h-8 mr-2">
                                 
                              </div>
                              {product.brand}
                            </td>
                            <td className="w-1/6 hover:text-blue-600"><Link to={`salestrack/${product.id}`}> {product.produit} </Link></td>
                            <td>{product.ref}</td>
                            <td className="w-18 bg-red-400 text-center">{product.price}</td>
                            <td className="w-18 bg-blue-400 text-center">{product.volume} - {product.unite}</td>
                            <td className="w-12 bg-green-400 text-center">{(product.visited)}</td>
                            <td className="text-center text-green-500" style={{"background": product.instock > 0 ? "#fff" : "#333" }}>{product.instock > 0 ? "Disponible" : "Repture"}</td>
                            <td className="w-20 bg-red-500 text-center">{product.instock}</td>
                            <td className="w-20 bg-yellow-500 text-center">{product.sold}</td>
                            <td>
                              <div className="flex flex-row">
                                  {/* edit */}
                                    <Link to={`/updateItem/${product.id}`} className="group"> 
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 group-hover:text-green-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                    </Link> 
                                   
                                    {/* delate */}
                                    <span className="group" onClick={()=>deleteItem(`${product.id}`)}> 
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 group-hover:text-red-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                      </svg>
                                    </span>

                                    <Link to={`/pub/${product.id}`} className="group"> 
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                      </svg>
                                    </Link>

                                    <Link to={`/label/${product.id}`} className="group"> 
                                      
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
                                      </svg>

                                    </Link>

                                    {/* add to slider 
                                    <span className="group" onClick={()=>addToSlider(`${product.id}`)} style={{color : product.slide == 1 ? "green" : "red"}}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 group-hover:text-blue-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
                                      </svg>
                                    </span>
                                    */}
                              </div>
                              
                            </td>
                          </tr>
                          
                      )
                    }
                  </tbody>
            </table>
            
        </div>
        </>
                    )
}

export default ListOfItems
