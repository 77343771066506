import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Card from './Card';
import { useCookies } from 'react-cookie';
import { FaPlay, FaAngleDown } from "react-icons/fa";
import SpecialOffer from './SpecialOffer';
import ParticleAnimation from 'react-particle-animation'
//import imag from '../images/1.png';

function AllProd() {
  //dotenv.config()
  const [products, setProducts] = useState([])
  const [discounted, setDiscounted] = useState([])
  const [load, setLoad] = useState(false)
  const urlAPI = `${process.env.REACT_APP_url}/product/all`
  const urlAPIRemise = `${process.env.REACT_APP_url}/product/remise`
  const [cookies, setCookie] = useCookies(['user']);
  const [brandListState, setBrandListState] = useState(false)
  const [categorie, setCategorie] = useState([])

    const urlAPICATS = `${process.env.REACT_APP_url}/categorie/categories.php`
  //setCookie('user', "null", { path: '/' });

  useEffect(() => {
    axios.get(urlAPI).then(res => { 
      setProducts(res.data)
     
      setLoad(true)
      
    }).catch(err => {
      console.log(err)
    })
  }, [])

  useEffect(()=>{
    axios.get(urlAPICATS).then(res => { 
        setCategorie(res.data)
        
      }).catch(err => {
        console.log(err)
      }) 
}, [])

useEffect(()=>{
  axios.get(urlAPIRemise).then(res => { 
    setDiscounted(res.data)
      
    }).catch(err => {
      console.log(err)
    }) 
}, [])

const openBrandLisst = () =>{
  let brandList = document.getElementById("brandList")
  if(brandListState == false){
    setBrandListState(!brandListState)
    brandList.classList.toggle("closeBrandList")
    brandList.classList.add("openBrandList")
  }else{
    brandList.classList.toggle("openBrandList")
    brandList.classList.add("closeBrandList")
    setBrandListState(!brandListState)
  }
}
  return (
    <div className="h-auto lg:w-full xs:w-full flex flex-col lg:px-10 xs:px-0">
        {/**  product */}

        
        <div className="flex lg:flex-row xs:flex-col">
            <div className="lg:w-1/6 xs:w-full h-auto bg-gray-100 mr-2 flex flex-col shadow-lg rounded-sm">
              <div className="w-full h-auto bg-yellow-400 flex items-center justify-between py-4 px-4">
                <span className="text-sm font-bold uppercase text-center">nos catégories</span>
                <span onClick={()=>openBrandLisst()} className="group">
                    <FaAngleDown className="lg:hidden xs:block group-hover:text-red-400" />
                </span>  
              </div>
              <div className="w-full h-auto px-4 border border-gray-400 border-t-0">
                  <ul className="w-full lg:h-auto xs:h-0 flex flex-col lg:py-2 xs:py-0 overflow-hidden" id="brandList">
                  {
                        categorie.map((c, index)=>
                            <Link //to={`/cat/${c.cat}`} 
                                  onClick={() => {window.location.href=`/cat/${c.cat}`}}
                                  className="w-full h-auto py px-2 mx-2  cursor-pointer  hover:bg-yellow-400 hover:shadow-md   hover:text-white">
                                    <div className="flex flex-row items-center py-1">
                                        <FaPlay className="w-2 h-2 mr-2 text-gray-700" />    
                                            <span className="text-gray-700 capitalize text-sm overflow-ellipsis overflow-hidden ">
                                            
                                              {c.cat}
                                            </span>
                                    </div>
                                
                            </Link>
                            
                        )
                    }
                  </ul>
              </div>
            </div>

            <div className="w-auto h-auto  flex flex-1 flex-col overflow-hidden  py-2 lg:px-4 xs:px-2">
                <div className="w-full h-auto bg-red-600 flex flex-row items-center justify-center py-4">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                      </svg>
                    </span>
                    <span className="text-xl font-black text-white uppercase"> 
                    <Link to={`/promotions`} className=' ' >
                        découvrez nos <span className='animate-ping text-yellow-500'>promotions</span> 
                    </Link> 
                    </span>
                </div>    
                <div className='w-full lg:h-72 xs:h-36 ' id="promotion"></div>
                <Card products={discounted} />
            </div>

        </div>
        <div className="w-full h-auto  mt-4 flex flex-col">
                    <div className="w-ful h-auto px-2 bg-yellow-400 ">
                      <span className="text-lg uppercase py-2 px-4 font-bold text-gray-700 align-middle">
                         peut-être qu'il vous plaira ?
                      </span>
                    </div>
                    <div className="flex flex-row flex-wrap px-4 py-2">
                      {
                        <Card products={products} />
                      }
                    </div>
        </div>            
    </div>
    
  )
}

export default AllProd
