import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import MostVisited from '../../components/admin/MostVisited'

const visited = () =>{
    return (
        <div>
            <AdminNav />
            <MostVisited />
        </div>
    )
}

export default visited
