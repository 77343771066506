import React from 'react'
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FaRegMoneyBillAlt } from "react-icons/fa";
const AdminNav = () =>{

    const openItemLi = (u) =>{
        let x = document.getElementById(u)
        x.classList.toggle("show") 
    }
    return (
        <div className="w-full h-auto flex flex-row justify-between border-b border-gray-100 shadow-md px-12 py-2">
            <div className="w-12 h-12 flex flex-row items-center justify-center">
                  <img className="h-10 w-10" src={require('../../images/logo/logo.png').default}/>
            </div>
            <ul className="w-auto h-12 px-2 flex flex-row   items-center space-x-2">
                
                
                {/** produit */}
                <li onClick={()=>openItemLi("itemsul")} className="w-auto h-10 px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-col rounded-lg transition  ease-out duration-500 group">
                    <span className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                    </svg>
                        articles</span>
                    <ul id="itemsul" className="absolute top-12 bg-white z-10 hidden flex-col w-auto px-4 py-4 border rounded-md mt-2 divide-y-2 divide-gray-100 transition ease-out duration-700 delay-700">
                        
                        
                         <Link to={"/items"} className="h-8 items-center  flex flex-row">
                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                             </svg>
                            <span className="text-sm capitalize font-bold text-gray-700 ml-2 roboto">list des produit</span>
                        </Link>



                        <Link to={"/additem"} className="h-8 items-center  flex flex-row">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
                                </svg>
                            <span className="text-sm capitalize font-bold text-gray-700 ml-2">nouveau article</span>
                        </Link>

                        
                        <Link to={"/visited"} className="h-8 items-center  flex">statistique</Link>
                        <Link to={"/addtostock"} className="h-8 items-center  flex">entrées stock</Link>
                        <li className="h-8 items-center  flex">les plus vendus</li>
                    </ul>
                </li>
                
                {/** brand */}
                <Link to={"/brands"} className="w-auto h-auto px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-row rounded-lg group">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                    <span>Marque</span>
                </Link>

                {/** categorie */}
                <Link to={"/categories"} className="w-auto h-auto px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-row rounded-lg group">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    <span>categories</span>
                </Link>

                <li className="w-auto h-auto px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-row rounded-lg group">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                    <span>clients</span>
                </li>
                
                <li onClick={()=>openItemLi("salesul")} className="w-auto h-10 px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-col rounded-lg transition  ease-out duration-500 group">
                    <span className="flex flex-row">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                        ventes</span>
                        
                        <ul id="salesul" className="absolute top-12 bg-white z-10 hidden flex-col w-auto px-4 py-4 border rounded-md mt-2 divide-y-2 divide-gray-100 transition ease-out duration-700 delay-700">
                            <Link to={"/sales"} className="h-8 items-center  flex">ventes</Link>
                            <Link to={"/salesstat"} className="h-8 items-center  flex">statistiques</Link>
                            <li  className="h-8 items-center  flex">
                                <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button bg-red-400 px-2 rounded-md border-red-500 border-2"
                                        table="table-to-xls"
                                        filename="tablexls"
                                        sheet="tablexls"
                                        buttonText="EXPORT XLS"/>
                            </li> 
                        </ul>
                </li>

                <li onClick={()=>openItemLi("orderul")} className="w-auto h-10 px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-col rounded-lg transition  ease-out duration-500 group">
                    <span className="flex flex-row">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                        commandes</span>
                        <ul id="orderul" className="absolute top-12 bg-white z-10 hidden flex-col w-auto px-4 py-4 border rounded-md mt-2 divide-y-2 divide-gray-100 transition ease-out duration-700 delay-700">
                            <Link to={"/allOrders"} className="h-8 items-center  flex">commandes</Link>
                            <Link to={"/orderlogs"} className="h-8 items-center  flex">logs</Link>
                        </ul>
                </li>

                

                

                  <li onClick={()=>openItemLi("labelop")} className="w-auto h-10 px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-col rounded-lg transition  ease-out duration-500 group">
                    <span className="flex flex-row">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2">
                             <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
                        </svg>

                        Choisir Dimension</span>
                        <ul id="labelop" className="absolute top-12 bg-white z-10 hidden flex-col w-auto px-4 py-4 border rounded-md mt-2 divide-y-2 divide-gray-100 transition ease-out duration-700 delay-700">
                            
                            <Link to={"/label"} className="h-8 items-center  flex">150mm / 100mm</Link>
                        </ul>
                </li>

            </ul>
        </div>
    )
}

export default AdminNav
