import React, {useEffect, useState, useRef  } from 'react'
import { useParams } from 'react-router'
import axios from "axios"
import MainItemImage from './MainItemImage';
const AlterItem = ( props ) =>{

    const { id  } = useParams();
    const urlAPI = `${process.env.REACT_APP_url}/product/product.php?idv=${id}`

    const [products, setProduct] = useState([])

    const [np, setNp] = useState("") 
   const [brand, setBrand] = useState([])
   const [ref, setRef] = useState("")
   const [price, setPrice] = useState("")
   const [remise, setRemise] = useState("")
   //const [qtt, setQtt] = useState("")
   const [volume, setVolume] = useState("")
   const [unite, setUnite] = useState("")
   const [categorie, setCategorie] = useState([])
   const [description, setDescription] = useState("")
   const [img, setImg] = useState([])
   
   const [cat, setCat] = useState("")
   const [brd, setBrd] = useState("")

   const [type, setType] = useState("")

   const [images, setImages] = useState([])
   
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
            setProduct(res.data)
            let p = res.data
            setNp(p[0].produit)
            setRef(p[0].red)
            setPrice(p[0].price)
            //setQtt(p[0].qtt)
            setVolume(p[0].volume)
            setUnite(p[0].unite)
            setCat(p[0].categorie)
            setDescription(p[0].descript)
            setBrd(p[0].brand)
            setType(p[0].type)
            setRemise(p[0].remise)
            setImages(JSON.parse(p[0].images))
        }).catch(err => {
            console.log(err)
            })
        }, [])

    //const [cat, setCat] = useState([])
    const urlAPICAT = `${process.env.REACT_APP_url}/categorie/categories`
    useEffect(() => {
        axios.get(urlAPICAT).then(res => { 
        setCategorie(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])

    const urlAPIBRAND = `${process.env.REACT_APP_url}/brand/allbrand`
    useEffect(() => {
        axios.get(urlAPIBRAND).then(res => { 
        setBrand(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])


    //console.log(images[0])
  
  
   let image_array = []
   for (let index = 0; index < img.length; index++) {
       const element = img[index];
       image_array[index] = element['name']

   }

   



let item = {
    nom : "",
    brand :"",
    ref: "",
    price : "",
   // qtt : "",
    remise : "",
    volume : "",
    unite : "",
    type : "",
    categorie : ""
}


let urlAPIUPDATEITEM 
const ref_np = useRef()
const ref_brand = useRef()
const ref_ref = useRef()
const ref_price = useRef()
//const ref_qtt = useRef()
const ref_remise = useRef()
const ref_volume = useRef()
const ref_unite = useRef()
const ref_cat = useRef()
const ref_desc = useRef()
const ref_type = useRef()

const update = (id) =>{
   
    item.nom = ref_np.current.value 
    item.brand = ref_brand.current.value
    item.ref = ref_ref.current.value
    item.price = ref_price.current.value 
    //item.qtt = ref_qtt.current.value
    item.remise = ref_remise.current.value 
    item.volume = ref_volume.current.value 
    item.unite = ref_unite.current.value 
    item.categorie = ref_cat.current.value
    item.type = ref_type.current.value

    let desc = ref_desc.current.value

    urlAPIUPDATEITEM = `${process.env.REACT_APP_url}/product/updateItem.php?
                                                                                d=${desc}
                                                                                &id=${id}
                                                                                &i=${JSON.stringify(item)}`
                                                                                //&pic=${JSON.stringify(image_array)}`


    
    
    axios.get(urlAPIUPDATEITEM).then((res) => {
        if(res.status == 200){
            alert("data update")
        }
      });

   } 

   let urlAPIUpdateImages = `${process.env.REACT_APP_url}/product/updateImage.php?
   id=${id}
   &pic=${JSON.stringify(image_array)}`
   
   const setIm = (e) =>{
        let files = e.target.files
        setImg(files)
        
    }

   const updateImages = () =>{
        let form = document.getElementById("mform")
        form.submit();
   }
/*
const urlAPIUPDATEITEM = `${process.env.REACT_APP_url}/product/updateItem.php?
                                                                                d=${description}
                                                                                &i=${JSON.stringify(item)}
                                                                                &pic=${JSON.stringify(image_array)}`
  */

                                                                                const addToSlider = (id) =>{
                                                                                    const urlAPISlide = `${process.env.REACT_APP_url}/slider/addslide.php?id=${id}`
                                                                                    axios.get(urlAPISlide).then(res => { 
                                                                                       // refreshPage()
                                                                                      }).catch(err => {
                                                                                      console.log(err)
                                                                                      })
                                                                                  }
                                                                                
        
         const [val, setValue] = useState(1)
         const [fact, setFact] = useState("")
         const updateInStock = `${process.env.REACT_APP_url}/product/newArrival.php?id=${id}&val=${val}&f=${fact}`
        const new_arrival = () =>{
        
            let regx = new RegExp(/^\d+$/)
            let reg = regx.test(val)
            if(val != 0 && reg == true){
            axios.get(updateInStock).then((res) => {
               if(res.status){
                   alert("qtt update")
               }
            });
            }
        }                       
 return (
        <div className="w-full h-screen bg-gray-100 flex flex-col">
            
            <div className="w-full h-auto flex flex-row bg-white border-b border-gray-700 px-12 py-4">
                <div className="flex flex-row w-full h-auto"> 
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="text-md font-bold capitalize">Modifier l'article :  </span>
                    
                </div>
            </div>
            <div className="w-full h-auto px-12 py-4  my-2 bg-white flex flex-row justify-end">
                <div className="w-1/4 h-auto px-2 py-2 justify-center items-center flex flex-col border-2 border-dashed border-gray-700">
                    <span className="text-xs text-red-400 font-bold uppercase mb-2">
                        Ajouter ce produit au slider.
                    </span>
                    <button 
                    onClick={()=>addToSlider(`${id}`)}
                    className="w-48 text-xs font-bold bg-yellow-400 uppercase rounded-full px-4 py-2 shadow-md">ajouter </button>
                </div>
            </div>
            {/** ROW 1 */}
            <div className="w-full h-auto flex flex-col bg-white px-12 py-2">
                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">nom de produit :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                                ref={ref_np}
                                value={np}
                                //onClick={this.value=""}
                                onChange={(e)=>setNp(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">marque : <span className="text-xs text-red-400">{brd}</span></span>
                            <select type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            ref = {ref_brand}
                            value={brd}
                             onChange={(e)=>setBrd(e.target.value)}
                            >
                                {
                                    brand.length &&  brand.map((b, index) =>
                                        <option >{b.brand}</option>        
                                    )
                                }
                            </select>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 group-focus:text-red-400 capitalize">ref / code :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400 group"
                            ref={ref_ref}
                            value={ref}
                            onChange={(e)=>setRef(e.target.value)}
                            ></input>
                        </div>
                </div>
                {/** ROW 2 */}
                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">prix :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            ref={ref_price}
                            value={price}
                            onChange={(e)=>setPrice(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">remise :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            ref={ref_remise}
                            value={remise}
                            onChange={(e)=>setRemise(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 group-focus:text-red-400 capitalize">Type :</span>
                            <select type="text" className="w-full h-10  border pl-8 focus:border-yellow-400 group"
                                ref = {ref_type}
                                value={type} 
                                onChange={(e)=>setType(e.target.value)}
                                 >
                                <option className='text-sm bg-green-400'>public</option> 
                                <option className='text-sm bg-red-400'>accrédités</option> 
                                <option className='text-sm bg-blue-400'>sur commande</option> 
                        </select>

                            
                        </div>
                </div>
                {/** ROW 4 */}
                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Volume :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            ref={ref_volume}
                            value={volume}
                            onChange={(e)=>setVolume(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">unite :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            ref={ref_unite}
                            value={unite}
                            onChange={(e)=>setUnite(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 group-focus:text-red-400 capitalize">categorie : <span className="text-xs text-red-400">{cat}</span></span>
                            <select type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                             ref={ref_cat}
                             value={cat}
                             onChange={(e)=>setCat(e.target.value)}
                            >
                                {
                                    categorie.length &&  categorie.map((cat, index) =>
                                        
                                        <option >{cat.cat}</option>        
                                    )
                                }
                                
                            </select>
                        </div>
                </div>

                
                {/** ROW 4 */}
                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-full flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">description :</span>
                            <textarea class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" rows="4"
                            ref={ref_desc}
                            value={description}
                            onChange={(e)=>setDescription(e.target.value)}
                           ></textarea>
                        </div>
                        
                </div>
                {/** button 1 alter item details */}
                <div className="w-full h-auto flex flex-row justify-end px-12 py-4">
                            {/** onClick={()=>additem()} */}
                            <button type="submit" className="w-auto h-auto px-4 py-2 rounded-full bg-yellow-400 text-gray-700 capitalize text-sm font-bold"
                            onClick={()=>update(id)}>modifier</button>
                 </div>
                 {/** nouveau arivage */}
                 <div className="w-full h-auto px-12 py-4  my-2 bg-white flex flex-row justify-end">
                        <div className="w-1/2 h-auto px-2 py-2 justify-center items-center flex flex-col border-2 border-dashed border-gray-700">
                            <span className="text-xs text-red-400 font-bold uppercase mb-2">
                                     nouvel arrivage.
                            </span>
                            <div className='w-full h-auto flex flex-row justify-around'>
                            <input type="text" 
                                        onChange={(e)=>setFact(e.target.value)}
                                    className="px-4 w-full h-10 px-4 text-red-500 my-2 mx-2 border-2 border-gray-700"></input>

                                <input type="number" 
                                        min="1"
                                        value={val}
                                        onChange={(e)=>setValue(e.target.value)}
                                    className="px-4 w-full h-10 px-4 text-red-500 my-2 mx-2 border-2 border-gray-700"></input>
                                    
                            </div>
                            
                            <button 
                            onClick={()=>new_arrival(`${id}`)}
                            className="w-48 text-xs font-bold bg-yellow-400 uppercase rounded-full px-4 py-2 shadow-md">ajouter </button>
                        </div>
                    </div>
                {/** MAIN IMAGE */}
                <div className="">
                                <MainItemImage  id_product={id}/>
                </div>

                {/** ROW 3 */}

                <div className="w-full h-auto py-2 flex flex-col justify-between">
                        <div className="w-full h-12 bg-yellow-400 px-2 items-center flex ">
                            <span className="text-md font-bold py-2">modifier les images :</span>
                        </div>
                        <div className="w-full h-24 py-2 flex flex-row bg-gray-50">
                                {images.length && images.map((image, index)=>
                                                   <div className="w-24 h-24 bg-cover bg-norepeat bg-center border-2 mx-2" 
                                                        style={{backgroundImage:`url(${process.env.REACT_APP_url}/product/images/${images[index]})`}}>
                                                            
                                                    </div> 
                                )}
                                
                        </div>
                        <div className="w-full flex flex-col space-y-2 px-2 mt-2">
                            
                            <span className="text-sm font-semibold pl-2 capitalize">images :</span>
                             {/*action={urlAPINEWITEM}*/} 
                            <form method="post" encType="multipart/form-data" action={urlAPIUpdateImages} id="mform" target="votar2">
                                <input type="file" 
                                    name="image[]"
                                    className="w-full h-10 py-2 border pl-8 focus:border-yellow-400"
                                    multiple
                                    //value={img.length}
                                    onChange={(e)=>setIm(e)}
                                ></input>
                       


                            </form>
                            
                        </div>
                        
                </div>
                                {/** BUTTON */}
                      <div className="w-full h-auto flex flex-row justify-end px-12 py-4">
                            {/** onClick={()=>additem()} */}
                            <button type="submit" className="w-auto h-auto px-4 py-2 rounded-full bg-yellow-400 text-gray-700 capitalize text-sm font-bold"
                            onClick={()=>updateImages(id)}>modifier</button>
                        </div>
                        <iframe name="votar2" className="none"></iframe>
            </div>
            
        </div>
    )
}

export default AlterItem
