import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import axios from 'axios';
const Labels = () =>{

  const [p, setP] = useState([])
  const [prdt, setPrdt] = useState([])
  const [mode, setMode] = useState([])
  const [o, setO] = useState([])
  const [volm, setVolm] = useState([])

    const { id } = useParams(); 
    const urlAPI = `${process.env.REACT_APP_url}/product/product.php?idv=${id}`

    useEffect(() => {
      axios.get(urlAPI).then(res => { 
      setP(res.data)
      
  }).catch(err => {
      console.log(err)
      })
  }, [])

  const downimg = ()=>{
     htmlToImage.toJpeg(document.getElementById('my-node'))
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = dataUrl;
        link.click();
      });
  }
  
  const addOrigine = () =>{
    var or = document.getElementById("orig").value;
    var prd = document.getElementById("prd").value;
    var mu = document.getElementById("mu").value;
    var vlm = document.getElementById("vlm").value;
    setO(or)
    setPrdt(prd)
    setMode(mu)
    setVolm(vlm)
  }


 
  


  const LabelStyle = {
    width:'576px',
    height: '384px'
    
    
  }
  return (

    <div className='w-full h-auto flex flex-row pt-8'>


          <div className='w-1/2 h-screen flex flex-col justify-center items-center bg-gray-200'>
              <div className='labelsize flex justify-center items-center mb-2 text-sm' style={LabelStyle} id="my-node">
                    <div className='border-2 border-dotted border-black tbx flex justify-center items-center flex-col'>
                   
                                    <div className='w-full text-center font-bold text-lg'>منتوج خاص بالمحترفين</div>
                                        <div className='w-full text-center flex flex-row justify-center items-center'>
                                          <span>{prdt}</span>
                                          <span>:إسم المنتج </span>
                                        </div>
                                        <div className='w-full text-center'>المستورد : ش ذ ذ م قوميس</div>
                                        <div className='w-full text-center'>العنوان: حي بن سونة رقم 63 الشلف 02000</div>
                                        <div className='w-full text-center'>الهاتف :027772917/0559096835</div>
                                        <div className='w-full text-center'>contact@gomispro.com : البريد الإلكتروني </div>
                                        
                                        <div className='w-full text-center flex flex-row justify-center items-center'>
                                          <span>{mode}</span>
                                          <span>:طريقة الإستخدام </span>
                                        </div>

                                        <div className='w-full text-center flex flex-row justify-center items-center'>
                                          <span>{o}</span>
                                          <span>:البلد المنتج </span>
                                        </div>
                                        
                                        <div className='w-full text-center flex flex-row justify-center items-center'>
                                          <span>{volm}</span>
                                          <span>:الحجم </span>
                                        </div>
                                  </div>
                          
               
          </div>


              <div className='w-full h-auto flex items-center justify-center'>
                   
                <button  type="button"
                  className='w-1/2 h-auto px-2 py-2 bg-yellow-400 flex flex-row justify-center rounded-md'
                  onClick={()=>downimg()}>

                        <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                                className="w-6 h-6 mr-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>
                        <p>
                          Télecharger label
                        </p>
                          
                     
                  </button>
              </div>

          </div>
          

          <div className='w-1/2 h-screen bg-white flex flex-col  px-8 py-8'>

              <div className='w-full h-auto py-2 flex flex-col '>
                <span className='font-bold '>Produit : </span>
                <input type="text" className='w-full h-10 border border-yellow-400 rounded-md pl-4' id="prd"></input>
              </div>

              <div className='w-full h-auto py-2 flex flex-col '>
                <span className='font-bold '>mode d'utilisation : </span>
                <input type="text" className='w-full h-10 border border-yellow-400 rounded-md pl-4' id="mu"></input>
                
              </div>
              

              <div className='w-full h-auto py-2 flex flex-col '>
                <span className='font-bold '>origine : </span>
                <input type="text" className='w-full h-10 border border-yellow-400 rounded-md pl-4' id="orig"></input>
                
              </div>

              <div className='w-full h-auto py-2 flex flex-col '>
                <span className='font-bold '>volume : </span>
                <input type="text" className='w-full h-10 border border-yellow-400 rounded-md pl-4' id="vlm"></input>
                
              </div>

              <div className='w-full h-auto py-2 flex flex-col items-center'>
                    <button  type="button"
                        className='w-1/2 h-auto px-2 py-2 bg-green-400 flex flex-row justify-center rounded-md'
                        onClick={()=>addOrigine()}>

                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"  
                                  className="w-6 h-6 mr-2 ">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>

                              <p>
                                 insérer
                              </p>
                                
                          
                        </button>
                
              </div>

             
                
                
            </div>


    </div>
    
    
  )
}

export default Labels