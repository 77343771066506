import React, {useState, useRef } from 'react'
import { Link, useHistory} from 'react-router-dom';
import axios from 'axios';
const Register = () =>{
    const history = useHistory()
    const [mail, setMail] = useState("")
    const [tel, setTel] = useState("")
    const [pss, setPss] = useState("") 

    const [validation, setValidation] = useState()

    const inputMail = useRef();

    let newUser =  {
        //idc : user[0].idc,
        //firstName: firstname,
        //lastName: lastName,
        //rp: rp,
        //adr: adr,
        //zip: zip,
        tel: tel,
        mail: mail,
        pss: pss
        //ville: street
      }

    const urlAPI = `${process.env.REACT_APP_url}/user/register.php?client=${JSON.stringify(newUser)}`
    
    

    const NewUser = () =>{
        if(mail == "" || tel == "" || pss == ""){
            //inputMail.current.className = inputMail.current.className + " bg-red-400"
            alert("voulez-vous saisie vos coordonnées")
        }else{
            axios.get(urlAPI).then((res) => {
                history.push('/profile')
              });
        }
        //console.log(inputMail.current.className)
        
        //inputMail.current.focus()
        /*
        axios.get(urlAPI).then((res) => {
            history.push('/profile')
          });
          */
    }

    return (
        <div className="lg:w-1/2 xs:w-full h-auto flex flex-col pl-4">
            <div  className="w-full h-auto py-2 border-b border-gray-100">
                <span className="text-lg font-semibold border-b-2 border-yellow-400 capitalize py-2 pr-10 leading-tight">
                         S'inscrire 
                </span>
            </div>


            <div className="w-full h-auto flex-col flex py-4 px-4 space-y-6">
                <div className="w-full -h-auto">
                    <span className="text-sm capitalize">
                        Créez un nouveau compte aujourd'hui pour profiter des avantages d'une expérience d'achat personnalisée. 
                    </span>
                </div>
                
                <div className="flex flex-col space-y-2">
                    <span className="text-sm font-semibold pl-2">e-mail </span>
                    <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400" required
                    ref={inputMail}
                    value={
                        mail
                        }
                onChange={(e)=>setMail(e.target.value)}></input>
                </div>

                <div className="flex flex-col space-y-2">
                    <span className="text-sm font-semibold pl-2">numéro de portable </span>
                    <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400" required
                    value={
                        tel
                        }
                onChange={(e)=>setTel(e.target.value)}></input>
                </div>

                <div className="flex flex-col space-y-2">
                    <span className="text-sm font-semibold pl-2">Mot de passe  </span>
                    <input type="password" className="w-full h-12 rounded-full border pl-8" required
                    value={
                        pss
                        }
                onChange={(e)=>setPss(e.target.value)}></input>
                </div>

                <div className="w-full h-auto px-4 py-2">
                    <span className="text-sm font-thin text-gray-800">
                    Vos données personnelles seront utilisées pour soutenir votre expérience tout au long de ce site Web, pour gérer l'accès à votre compte et à d'autres fins décrites dans notre politique de confidentialité.     
                    </span>    
                </div>    
                
                <div>
                    <button className="w-auto h-auto py-2 px-6 bg-yellow-400 rounded-full text-sm font-semibold"
                    onClick={()=>NewUser()}>Register</button>
                </div>

                <div className="w-full h-auto flex flex-col space-y-2">
                    <span className="text-gray-900 text-md ">
                        Inscrivez-vous aujourd'hui et vous pourrez : 
                    </span>
                    <ul className="w-full h-auto pl-6">
                        <li className="text-gray-700 text-xs font-light mb-2">Accélérez votre passage à la caisse</li>
                        <li className="text-gray-700 text-xs font-light mb-2"> Suivez vos commandes facilement</li>
                        <li className="text-gray-700 text-xs font-light mb-2">Gardez une trace de tous vos achats </li>
                    </ul>
                </div>
            </div>


        </div>
    )
}

export default Register
