import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import CreateItem from '../../components/admin/CreateItem'

const additem = () => {
    return (
        <div>
            <AdminNav />
            <CreateItem />
        </div>
    )
}

export default additem
