import React from 'react'
import Labels from '../../components/admin/Labels'
import AdminNav from '../../components/admin/AdminNav'
const label = () =>{
  return (
    <div className=''>
            <AdminNav />
            <Labels />
    </div>
  )
}

export default label