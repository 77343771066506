import React, {useEffect, useState, useRef} from 'react'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const ListOfSales = () =>{
    const [orders, setOrders] = useState([])
    const [p, setP] = useState([])
    const [search, setSearch] = useState()
    const [ttl, setTotal] = useState(0)
    const [startDate, setStartDate] = useState(new Date());
    const refreshPage = ()=>{
      window.location.reload();
   }

    let tot = 0
    const setTot = (t) =>{
        tot = tot + t
        setTotal(tot)
    }
   //const br = useRef(null)
   const urlAPI = `${process.env.REACT_APP_url}/orders/allsales`

   useEffect(() => {
         axios.get(urlAPI).then(res => { 
         setOrders(res.data)
         //setLoad(true)
         
         }).catch(err => {
         console.log(err)
         })
     }, [])
     
    
     console.log(orders)

    const  valider = (id) =>{
        var timer = 0;
        var delay = 200;
        var prevent = false;

     const urlAPIRETURN = `${process.env.REACT_APP_url}/orders/return.php?id=${id}`
     timer = setTimeout(function(){
      if(!prevent){
        axios.get(urlAPIRETURN).then(res => { 
          refreshPage()
          //setLoad(true)
          
          }).catch(err => {
          console.log(err)
          })
      }
      prevent = false;
    }, delay)
     alert(" Facture retirée des ventes !")
    }

    const resetTable = () => {
      orders.length && orders.map((p, index) =>
        {
          let tr = "tr"+index
          let row = document.getElementById(tr)
          row.classList.remove("hidden")
          row.classList.remove("bg-green-300")
          //row.classList.add("h-12 border-b border-gray-300 py-2") 
        }
      )
    }

    const getThisorder = (sb) =>{
      
      let x = "h-12 border-b border-gray-300 py-2"
      if(sb == ""){
        resetTable()
      }else{
         orders.length && orders.map((p, index) =>
           { 
             let tr = "tr"+index
             let row = document.getElementById(tr) 
             let id = p.ido 
             //row.classList = ''
             if(id.toLowerCase().includes(sb.toLowerCase())){
              
               row.classList.add("bg-green-300")
             }else{
               
               row.classList.add("hidden")
             }
           }
         )
      }
    }

    const getThisBiling = (sb) =>{
      
      let x = "h-12 border-b border-gray-300 py-2"
      if(sb == ""){
        resetTable()
      }else{
         orders.length && orders.map((p, index) =>
           { 
             let tr = "tr"+index
             let row = document.getElementById(tr) 
             let id = p.facture 
             //row.classList = ''
             if(id.toLowerCase().includes(sb.toLowerCase())){
              
               row.classList.add("bg-green-300")
             }else{
               
               row.classList.add("hidden")
             }
           }
         )
      }
    }

    const getThisTracking = (sb) =>{
      
      let x = "h-12 border-b border-gray-300 py-2"
      if(sb == ""){
        resetTable()
      }else{
         orders.length && orders.map((p, index) =>
           { 
             let tr = "tr"+index
             let row = document.getElementById(tr) 
             let id = p.tracking 
             //row.classList = ''
             if(id.toLowerCase().includes(sb.toLowerCase())){
              
               row.classList.add("bg-green-300")
             }else{
               
               row.classList.add("hidden")
             }
           }
         )
      }
    }

    const getThisTel = (sb) =>{
      
      let x = "h-12 border-b border-gray-300 py-2"
      if(sb == ""){
        resetTable()
      }else{
         orders.length && orders.map((p, index) =>
           { 
             let tr = "tr"+index
             let row = document.getElementById(tr) 
             let id = p.tel 
              
             //row.classList = ''
             if(id.toLowerCase().includes(sb.toLowerCase())){
              console.log(p.total)
               row.classList.add("bg-green-300")
             }else{
               
               row.classList.add("hidden")
             }
           }
         )
      }
    }

    const getThisName = (sb) =>{
      
      let x = "h-12 border-b border-gray-300 py-2"
      if(sb == ""){
        resetTable()
      }else{
         orders.length && orders.map((p, index) =>
           { 
             let tr = "tr"+index
             let row = document.getElementById(tr) 
             let fn = p.firstname
             let ln = p.lastname 
             //row.classList = ''
             if(fn.toLowerCase().includes(sb.toLowerCase()) || ln.toLowerCase().includes(sb.toLowerCase())){
              
               row.classList.add("bg-green-300")
             }else{
               
               row.classList.add("hidden")
             }
           }
         )
      }
    }
    let income = 0
    const handleCalendarClose = () =>{
      let sd = document.getElementById("selctdate").value
      resetTable()

      orders.length && orders.map((p, index) =>
           { 
             let tr = "tr"+index
             let row = document.getElementById(tr) 
             let d = p.date 
             let i = p.total
              
             //row.classList = ''
             if(d.toLowerCase().includes(sd.toLowerCase())){
              //console.log(p.total)
               row.classList.add("bg-green-300")
               income = income + parseFloat(i)
             }else{
               
               row.classList.add("hidden")
             }
           }
      )
      setTotal(income)
    }
    
    
    return (
        <div className="w-full h-auto overflow-hidden flex flex-col px-12">
        <table className="table-fixed" id="table-to-xls">
          <thead className="bg-yellow-400">
           
            <tr className="text-sm text-left h-12 border-b border-gray-700 bg-gray-200" >
              <th className='pl-2'>N° Commande</th>
              <th className='text-center'>N° Facture</th>
              <th className='text-center'>Tracking</th>
              <th className='text-center'>Date</th>
              <th className='text-center'>Nom & Prénom</th>
              <th className='text-center'>Tel </th>
              <th className='text-center w-36'>total</th>
              <th className='text-center'>Etat</th>
              <th className='text-center'>Mode payement</th>
              <th className='text-center'>PMT</th>
              <th className='text-center'>actions</th>
            </tr>

            <tr className="text-sm text-left h-12 border-b border-yellow-700 bg-yellow-400 px-2" >
              <th className='pl-2'><input type="text" className='h-8 w-20 rounded' onChange={(e)=>getThisorder(e.target.value)}></input></th>
              <th><input type="text" className='h-8 w-20 rounded' onChange={(e)=>getThisBiling(e.target.value)}></input></th>
              <th><input type="text" className='h-8 w-36 rounded' onChange={(e)=>getThisTracking(e.target.value)}></input></th>
              <th className='text-center '>
                <DatePicker id='selctdate'
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  selected={startDate} 
                  onChange={(date) =>  setStartDate(date)}
                  onCalendarClose={handleCalendarClose}
                  className="h-8 w-18 rounded text-center"
                />
                
              </th>
              <th><input type="text" className='h-8 w-36 rounded' onChange={(e)=>getThisName(e.target.value)}></input></th>
              <th><input type="text" className='h-8 w-36 rounded' onChange={(e)=>getThisTel(e.target.value)}></input></th>
              <th className='text-center text-blue-600'>{ttl.toString().split()} - DA</th>
              <th className='text-center'>etat</th>
              <th className='text-center'>Mode payement</th>
              <th className='text-center'>PMT</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {
              orders.length && orders.map((order, index)=>
              <tr className="h-12 border-b border-gray-300 py-2 hover:bg-gray-200" style={{"background": (order.ep).toLowerCase().includes("non payée") ? "#F87474" : "#fff" }} id={`tr${index}`}>
                  <td className='text-center text-sm'>{order.ido}</td>
                  <td className='text-center text-sm'>{order.facture}</td>
                  <td className='text-center text-sm'>{order.tracking}</td>
                  <td className="flex flex-row justify-center text-sm">{order.date}</td>
                  <td className='text-center text-sm'>{order.firstname} - {order.lastname}</td>
                  <td className='text-center text-sm'>{order.tel}</td>
                  <td className='text-sm font-bold text-center ' style={{"color": (order.ep).toLowerCase().includes("non payée") ? "#333" : "#F87474" }}>{order.total} - DA</td>
                  <td className='text-center text-sm'>{order.ep}</td>
                  <td className='text-center text-sm'>{order.mp}</td>
                  <td className='text-center text-sm'>{order.pmt}</td>
              
                  <td>
                      <div className="flex flex-row">
                          {/* edit */}
                          <div onClick={()=>window.open(`/visualise/${order.ido}`, "_blank")} className="w-auto h-auto p-2 hover:bg-yellow-400 group rounded-md shadow-md">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-500 group-hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                </svg>
                          </div>

                          <Link to={`/info/${order.ido}`} className="w-auto h-auto p-2 hover:bg-yellow-400 group rounded-md shadow-md">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500 group-hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                              </svg>
                          </Link>

                          <button className="w-auto h-auto p-2 hover:bg-yellow-400 group rounded-md shadow-md"
                                  onClick={()=>valider(order.ido)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 group-hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>   

                      </div>
                      
                    </td>
            </tr>
              
              )
            }
          </tbody>
    </table>
</div>
    )
}

export default ListOfSales
