import React, {useEffect, useState } from 'react'
import Details from './Details';

class NewDetails extends React.Component {
    constructor(props) {
      super(props);
      this.handler = this.handler.bind(this)
      this.state = {
            
      };
    }
    handler() {
        this.setState({
          messageShown: false
        });
    }
    render() {
      return <Details />
    }
  }
  export default NewDetails;