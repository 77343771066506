import React, { useState, useEffect } from 'react'
import { useRef } from 'react';
import axios from 'axios'
import { Bar, getElementAtEvent } from 'react-chartjs-2'

const AllSales = () =>{
    const [client, setClient] = useState([])
    const [productlabel, setProductLabel] = useState([])
    const [productdata, setProductData] = useState([])
    const chartRef = useRef();
    let pl_array = [] //product_labbel_array
    let pd_array = [] //product_datasets_array
    const urlAPIClient  = `${process.env.REACT_APP_url}/orders/mostorder`
    useEffect(() => {
        axios.get(urlAPIClient).then(res => { 
           setClient(res.data)
          }).catch(err => {
            console.log(err)
          })
    }, [])
  return (
    <div className="w-full h-auto overflow-hidden flex flex-col px-12">
        <table className="table-fixed" id="table-to-xls">
            <thead className="bg-yellow-400">
            <tr className="text-sm text-left h-12 border-b border-gray-700 bg-gray-200" >
                <th className='text-center'>N°</th>
                <th className='text-center'>TEL</th>
                <th className='text-center'>Client</th>
                <th className='text-center'>Nombre De Commande</th>
                <th className='text-center'>total Des Achats (da) </th>
            </tr>
                
            </thead>
            <tbody>
            {
                    client.length && client.map((cl, index)=>
                    <tr className="h-12 border-b border-gray-300 py-2 hover:bg-gray-200"  id={`tr${index}`}>
                         <td className='text-center text-sm'>{index+1}</td>
                         <td className='text-center text-sm'>{cl.tel}</td>
                         <td className='text-center text-sm'>{cl.firstname}_{cl.lastname}</td>
                         <td className='text-center text-sm'>{cl.num}</td>
                         <td className='text-center text-sm'>{cl.tot}</td>
                    </tr>
                    )
                }
            </tbody>
        </table>
    </div>
  )
}

export default AllSales