import React, {useEffect, useState } from 'react';
import axios from "axios"

const OrderLogsList = () =>{
    const urlAPI = `${process.env.REACT_APP_url}orders/listoflogs`
    const [listorder, setListOrder] = useState([])
   
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
            setListOrder(res.data) 
          }).catch(err => {
            console.log(err)
          })
  }, [])

  console.log(listorder)

  const resetTable = () => {
    listorder.length && listorder.map((p, index) =>
      {
        let tr = "tr"+index
        let row = document.getElementById(tr)
        row.classList.remove("hidden")
        row.classList.remove("bg-green-300")
        //row.classList.add("h-12 border-b border-gray-300 py-2") 
      }
    )
  }

  const getThisorder = (sb) =>{
      
    let x = "h-12 border-b border-gray-300 py-2"
    if(sb == ""){
      resetTable()
    }else{
       listorder.length && listorder.map((p, index) =>
         { 
           let tr = "tr"+index
           let row = document.getElementById(tr) 
           let id = p.idorder
           //row.classList = ''
           if(id.toLowerCase().includes(sb.toLowerCase())){
            
             row.classList.add("bg-green-300")
           }else{
             
             row.classList.add("hidden")
           }
         }
       )
    }
  }

  return (
    <div className='w-full h-auto '>
        
        <table className="table-fixed w-full">
             <thead className="bg-yellow-400">
                 <tr className="text-sm text-left h-12 border-b border-gray-700 bg-gray-200 px-2" >
                      <th className="text-center">ID</th>
                      <th className="text-center">N° FACTURE</th>
                      <th className="text-center">Client</th>
                      <th className="text-center">Tel</th>
                      <th className="text-center">Total</th>
                      <th className="text-center">Date</th>
                      <th className="text-center">Modifier Le</th>
                 </tr>

                 <tr className="text-sm text-left h-12 border-b border-yellow-700 bg-yellow-400 px-2" >
                   <td className='h-12' colSpan={7}>
                   <div className='w-full h-12  flex flex-row items-center'>
                         <span className='text-sm uppercase mr-2'>chercher par n° de commande :</span>
                         <input className='w-48 h-8 rounded ' onChange={(e)=>getThisorder(e.target.value)}></input>
                     </div>
                   </td>
                 </tr>
                    
             </thead>
             <tbody>
                 {
                     listorder.length && listorder.map((lo, index)=>
                     <tr className="h-12 border-b border-gray-300 py-2 hover:bg-gray-200" id={`tr${index}`}>
                         <td className='w-1/3 text-center'>{lo.id}</td>
                         <td className='w-1/3 text-center'>{lo.idorder}</td>
                         <td className='w-1/3 text-center'>{lo.firstname} - {lo.lastname}</td>
                         <td className='w-1/3 text-center'>{lo.tel}</td>
                         <td className='w-1/3 text-center'>{lo.total}</td>
                         <td className='w-1/3 text-center'>{lo.date}</td>
                         <td className='w-1/3 text-center'>{lo.dt}</td>
                     </tr>
                     )
                 }
             </tbody>
        </table>
    </div>
  )
}

export default OrderLogsList