import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import NewBrand from '../../components/admin/NewBrand'

const newbrand = () =>{
    return (
        <div>
            <AdminNav />
            <NewBrand />
        </div>
    )
}

export default newbrand
