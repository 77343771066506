import React from 'react'
import MyDocument from '../components/MyDocument'
import Nav from '../components/Nav'
import Successfull from '../components/Succesfull'
import ReactPDF from '@react-pdf/renderer';
 const  success = () => {
    
    return (
        <div>
            <Nav />
            <Successfull />
           
        </div>
    )
}
export default success