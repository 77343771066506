import React from 'react'
import Nav from '../components/Nav'
const cancelled = () =>{
    return (
        <div className="w-full h-auto flex flex-col mt-36">
            <Nav />
            <div className="w-full h-screen bg-contain bg-no-repeat bg-center mt-28 flex justify-center items-center" 
                 style={{backgroundImage:`url(${require("../images/cancel.svg").default})`}}>
                <span className="text-5xl font-bold capitalize px-4 bg-red-400 py-4">
                    votre transaction à ete annulé.
                </span>
            </div>
        </div>
    )
}

export default cancelled
