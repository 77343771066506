import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from "axios"
import CartNumber from './cartNumber';
import { useCookies, withCookies, Cookies } from 'react-cookie';
import Card from './Card';
const Navl2 = () => {
    const [cookies, setCookie] = useCookies(['user_name']);
    //const { count } = useSelector(state => state.cartCounter)
    const count = useSelector((state)=> state.cartCounter.count)
    const amount = useSelector((state)=> state.totalInCart.total)

    const [search, setSearch] = useState("")
    const [sp, setSP] = useState([])
    //const dispatch = useDispatch()
    const APIsearch = `${process.env.REACT_APP_url}/search/search.php?s=${search}`

    const searchIt = () => {
        axios.get(APIsearch).then(res => { 
            setSP(res.data)
            
        }).catch(err => {
            console.log(err)
            })
            let searchC = document.getElementById("searchC")
            searchC.classList.add("show")
    }
    const openSearch=()=>{
        let search = document.getElementById("search")
        //menuContainer.style.display = "flex"
        search.classList.toggle("show")
        
    }

    const closeSearch = () =>{
        let searchC = document.getElementById("searchC")
        searchC.classList.remove("show")
    }
    const openMenu=()=>{
        let menuContainer = document.getElementById("menuContainer")
        //menuContainer.style.display = "flex"
        menuContainer.classList.toggle("show")
    }
    return (
        <div className="w-full">
            <div className="w-full h-auto lg:px-12 lg:py-4 xs:px-2 xs:py-2 flex flex-row justify-between z-10">
                    <Link  to="/" className="w-12 h-12 py-2 cursor-pointer">
                        <img className="h-10 w-10" src={require('../images/logo/logo.png').default}/>
                    </Link>
                    <div className="lg:w-4/6 lg:px-8 xs:hidden">
                        <div className="border-2 w-3/4 h-auto border-yellow-400 rounded-full overflow-hidden flex flex-row flex-grow">
                            <input type="text" className="w-full" />
                            <button className="bg-yellow-400 w-12 h-12 text-center px-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 font-bold" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="lg:w-auto lg:h-12">
                        <ul className="w-auto h-12 space-x-6 flex flex-row justify-between items-center">
                            <li className="w-6 h-6 text-center align-middle cursor-pointer" onClick={()=>openSearch()}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </li>
                            <li className="w-6 h-6 text-center align-middle">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </li>
                            <Link to={ cookies.state === "true" ? "/profile" : "/account"
                                    
                                }>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </Link>
                            <Link to={`/cart`} className="">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                </svg>
                                <div className="w-6 h-6 rounded-full bg-yellow-500 flex justify-center items-center absolute -mt-2 -ml-2 ">
                                    
                                        <span className="text-sm font-bold">
                                            {count}
                                        </span>
                                
                                    
                                </div>
                                <span className="text-sm font-bold xs:hidden lg:inline-block">{parseFloat(amount)}</span>
                            </Link>

                            <li className="w-6 h-6 text-center align-middle cursor-pointer" onClick={()=>openMenu()}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </li>
                        </ul>
                    </div>
            </div>
            <div className="w-full h-12 hidden justify-center items-center flex-col bg-gray-200 py-2" id="search">
                <div className="w-full h-auto flex flex-row justify-center">
                                   <input type="text" className="lg:w-1/4 xs:w-full h-10 rounded-l-full pl-4"
                                   value = {search}
                                   onChange={(e)=>setSearch(e.target.value)}></input>
                                   <button className="w-10 h-10 bg-yellow-400 rounded-r-full text-center px-2" onClick={()=>searchIt()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>    
                                   </button> 
                </div>
                <div className="w-full h-screen flex-col hidden bg-white absolute top-32 overflow-y-scroll" id="searchC">
                     <div className="w-full h-12 flex flex-row justify-end px-12 py-2 cursor-pointer" onClick={()=>closeSearch()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                     </div>
                    <Card products={sp} />
                </div>
            </div>
            
        </div>
    )
}

export default Navl2
