import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import ListOfSales from '../../components/admin/ListOfSales'

const sales = () =>{
    return (
        <div>
            <AdminNav />
            <ListOfSales />
        </div>
    )
}

export default sales
