import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import axios from "axios"
import Card from './Card';
import SmallCard from './SmallCard';

const SameCategorie = (props) => {

    const [products, setProd] = useState([])
    const { id, brand } = useParams();

    const urlAPI = `${process.env.REACT_APP_url}/product/samecategorie.php?c=${props.categorie}`

    useEffect(() => {
        axios.get(urlAPI).then(res => { 
        setProd(res.data)
        }).catch(err => {
        console.log(err)
        })
    }, [])


    return (
        <div className="w-full h-auto px-8 overflow-hidden">
            <div className="w-full h-auto px-4 py-2 mb-2">
                <span className="text-xl font-bold capitalize border-b border-yellow-400 pb-2 px-2">même catégorie</span>
            </div>
            
            <SmallCard products={products}/>
             
        </div>
    )
}

export default SameCategorie
