import React from 'react'
import { Link, useHistory} from 'react-router-dom';
import jsPDF from 'jspdf';
import printJS from 'print-js'
import AdminCP from './AdminCP'
import AdminTP from './AdminTP';
import axios from "axios"

import { Route, useParams } from 'react-router-dom'
import { withRouter } from "react-router"; 

class ClientOrder extends React.Component {
    constructor(props){
      super(props);
      this.state = {}
    }
    
     

   
  
   
   urlAPI = `${process.env.REACT_APP_url}/orders/updateorder.php?id=${30}&o=${JSON.stringify(localStorage.getItem('clientpanier'))}`
      
    generatePdf1=()=>{
      
          var doc = new jsPDF({
              orientation:'portrait',
              unit:'pt',
              format:'a2'
          }); 
          

          //var doc = new jsPDF('p', 'pt');
          
          doc.html(document.querySelector("#content"), {
            callback: function (doc) {
              doc.save("GOMISPRO.pdf");
            }
          });
          
          //alert("FROM TOPRINT")
      }
      

      generatePdf=()=>{
        var contents = document.getElementById("content")
        //printJS(contents, 'html')
        console.log("printing document")
        printJS({
          printable: contents,
          type: 'pdf',
          showModal: true,
          //onPrintDialogClose: () => alert('The print dialog was closed'),
          //onIncompatibleBrowser: () => console.log('Pdf was opened in a new tab due to an incompatible browser')
        })
      }
      saveOrder = () =>{
        
        const ido = parseInt(localStorage.getItem('orderId'))
        let pinp = JSON.parse(localStorage.getItem('clientpanier'))
        let tt = 0
        {
                                    Object.keys(pinp).map((key, index)=>{
                                      tt = tt + ((pinp[key].itemqtt * pinp[key].itemprice) * ((100 - pinp[key].itemremise)/100 ))
                                                                         
                                   })
        }
       
        let urlAPI = `${process.env.REACT_APP_url}/orders/updateorder.php?id=${ido}&t=${tt}&o=${JSON.stringify(localStorage.getItem('clientpanier'))}`
        
        axios.get(urlAPI).then((res) => {

        });
        
      }
  render(){
    return( 
      <div className="pt-20">
    
        <AdminCP />
        <div className="w-full h-auto bg-blue-400 hidden" >
            <AdminTP />
        </div>
        <div className="w-full h-auto flex flex-row justify-center items-center ">
          <button 
                    onClick={this.generatePdf1}
                    className="w-auto h-auto bg-blue-400 text-white px-4 py-2 ">Imprimer</button>
           
           <Link    to={"/updateorder"}
                    className="w-auto h-auto bg-blue-400 text-white px-4 py-2 ">Modifier</Link>

           <button 
                    onClick={this.saveOrder}
                    className="w-auto h-auto bg-blue-400 text-white px-4 py-2 ">enregistrer</button>
        </div>
      </div>
    )
  }
  
  }
  
export default ClientOrder
