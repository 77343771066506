import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import VisualiseOrder from '../../components/admin/VisualiseOrder'

const visualise = () =>{
  return (
    <div>
        <VisualiseOrder />
    </div>
  )
}

export default visualise