import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

const Navl3 = () => {
    const [categorie, setCategorie] = useState([])
    const urlAPI = `${process.env.REACT_APP_url}/categorie/categories.php`

    useEffect(()=>{
        axios.get(urlAPI).then(res => { 
            setCategorie(res.data)
            
          }).catch(err => {
            console.log(err)
          }) 
    }, [])

    const refreshPage = ()=>{
        window.location.reload();
     }

    return (
        <div className="flex-row h-auto w-full bg-grey-50 lg:px-12 lg:mb-6 hidden" id="menuContainer">
            
            <ul className="w-full flex flex-row flex-wrap py-2 overflow-x-hidden overflow-y-scroll max-h-screen">
                {
                    categorie.map((c, index)=>
                        <Link //to={`/cat/${c.cat}`} 
                              onClick={() => {window.location.href=`/cat/${c.cat}`}}
                              className="xs:w-1/4 lg:w-1/6 h-auto py px-2 mx-2 my-1 border border-gray-200 cursor-pointer rounded-lg hover:bg-yellow-400 hover:shadow-md   hover:text-white">
                                <div className="flex lg:flex-row xs:flex-col items-center py">
                                        <div className="w-16 h-16 mr-2 bg-cover bg-center" style={{backgroundImage:`url(${process.env.REACT_APP_url}/categorie/images/${c.image})`}}>
                                        
                                        </div>
                                        <span className="text-xs overflow-ellipsis overflow-hidden">
                                        {c.cat}
                                        </span>
                                </div>
                            
                        </Link>
                        
                    )
                }
            </ul>
            
        </div>
    )
}

export default Navl3