import React, {useEffect, useState } from 'react';
import { Link, useHistory} from 'react-router-dom';
import axios from "axios"
import AddToOrder from './AddToOrder';

const AlterOrder = () =>{
    const [order, setOrder] = useState([])
    const [client, setClient] = useState([])
    
    const [total, setTotal] = useState(0)
    let panier = JSON.parse(localStorage.getItem('clientpanier'))
    let ls_client = JSON.parse(localStorage.getItem('client'))
    let idorder = JSON.parse(localStorage.getItem('orderId'))
    

    const urlAPI = `${process.env.REACT_APP_url}admin/items`
    const [products, setProducts] = useState([])
    //const [item, setItem] = useState([])
    const [ref, setRef] = useState("")
    console.log("products")
    console.log(products)
    console.log("ORDER")
    console.log(order)
    const history = useHistory()

    useEffect(()=>{
        setOrder(panier)
        setClient(ls_client)

    }, [])


    useEffect(() => {
            axios.get(urlAPI).then(res => { 
                setProducts(res.data) 
              }).catch(err => {
                console.log(err)
              })
      }, [])

    const refreshPage = ()=>{
        window.location.reload();
     }

    const setRemise = (index,id, r) =>{
        let panier = JSON.parse(localStorage.getItem('clientpanier'))
        panier[id].itemremise = r
        localStorage.setItem('clientpanier', JSON.stringify(panier))
        refreshPage()
   }

   const setqtt = (index,id, r) =>{
        let panier = JSON.parse(localStorage.getItem('clientpanier'))
        panier[id].itemqtt = r
        localStorage.setItem('clientpanier', JSON.stringify(panier))
        refreshPage()
    }



    const removeProduct = (id) =>{
        let panier = JSON.parse(localStorage.getItem('clientpanier'))
        delete panier[id]
        localStorage.setItem("clientpanier", JSON.stringify(panier));
        alert("remove product : "+id)
        refreshPage()
    }

    let t = 0
    const print = (p,q,r) =>{
        let v = ((p*q)*((100-r)/100))
        t = t + v
        //setTotal(t) < x >
        
    }

    useEffect(()=>{
        setTotal(t)
    }, [])

    
    const getRef = (r) =>{
        setRef(r)
        console.log(r)
        
    }

    const searchProduct= (r) =>{
        let fl = document.getElementById("foundList")
        let cn = "w-full h-10 bg-red-500"
        products.length && products.map((product, index)=>{
            let rf = product.ref
            if(rf.toLowerCase().includes(r.toLowerCase())){
                let elemnt = document.getElementById("tr"+index)
                elemnt.classList.remove("hidden")
               
               // setItem(product)
               //let newLi = document.createElement("li")
               //newLi.classList.add("h-10")
               //let sp_product = document.createElement("span")
               //sp_product.innerText(product.produit)
               //newLi.appendChild(sp_product)
               //fl.appendChild(newLi)
            }
        })
        //console.log(item)
    }

    const resetTable = () => {
        let fl = document.getElementById("foundList")
        let cn = "w-full h-10 bg-red-500"
        products.length && products.map((product, index) =>
          {
            let tr = "tr"+index
            let row = document.getElementById(tr)
            row.classList.add("hidden")
            //row.classList.add("h-12 border-b border-gray-300 py-2") 
          }
        )
      }

    const searchItem = (r) =>{
       if(r == ""){
          resetTable()
       }else{
          products.length && products.map((p, index) =>
            { 
              let tr = "tr"+index
              let row = document.getElementById(tr) 
              let item = p.ref //produit
              //console.log(p)
              //row.classList = ''
              if(item.toLowerCase().includes(r.toLowerCase())){
                let elemnt = document.getElementById("tr"+index)
                elemnt.classList.remove("hidden")
              }else{
                
                row.classList.add("hidden")
              }
            }
          )
       }
    }

    const addToOrder = (id, name, brand,price, v, thumb, qtt, rem) =>{
          
           let item = {
            itemid:id,
            itemname:ref+" - "+name,
            itembrand:brand,
            itemprice:price,
            itemvolum:v,
            itemthumb:thumb,
            itemqtt:qtt,
            itemremise:rem
           }
           panier[id] = item
           localStorage.setItem('clientpanier', JSON.stringify(panier))
           refreshPage() 
    }

    
    const validate = () =>{
        const urlAPIALTERORDER = `${process.env.REACT_APP_url}/orders/alterorder.php?o=${JSON.stringify(panier)}&id=${idorder}&t=${t}`
        axios.get(urlAPIALTERORDER).then((res) => {
            history.push('/allOrders')
          });
    } 
    const check2 = (k, q) =>{
        products.length && products.map((p, i)=>{
            console.log("===============>")
            if(p.id == k){
             if(products[i].instock < q){
                    let rw = document.getElementById(k)
                    rw.classList.add("bg-yellow-400")
                }

            }
        })
    }
    const check = (order) =>{
        Object.keys(order).map((key, index)=>
        {
            console.log("##########>")
            console.log(order[key].itemid)
            let qtt = order[key].itemqtt
            //console.log(products[key].produit)
            check2(key, qtt)
        })    
    }

    check(order)

    return(
      <div className='h-full h-screen flex flex-col py-4 '>
          <div className='w-full h-auto flex flex-row items-center justify-end'>
              <div className='w-1/3 h-auto flex flex-col border rounded-md px-2 py-2'>
                            <div className='w-full h-10 flex flex-row items-center justify-center bg-yellow-400'>
                                <span className='text-sm font-bold text-black uppercase'>client</span>
                            </div>    
                            <div className='w-full h-10 flex flex-row border-b '>
                                <div className='w-1/3 text-sm font-bold capitalize mr-2'>nom & prénom</div>
                                <div className='text-sm'>{client.firstname} - {client.lastName}</div>
                            </div>
                    
                            <div className='w-full h-10 flex-row border-b flex'>
                                <div className='w-1/3 text-sm font-bold capitalize mr-2'>tel</div>
                                <div className='text-sm'>{client.tel}</div>
                            </div>

                            <div className='w-full h-10 flex-row border-b flex'>
                                <span className='w-1/3 text-sm font-bold capitalize mr-2'>adress</span>
                                <span className='text-sm '>{client.adr}</span>
                            </div>
              </div>
          </div>
          <div>
          <div className='w-full h-auto flex flex-col mb-2'>
                <div className='w-full h-16 bg-gray-200 border border-gray-300 mb-2 flex flex-row justify-center items-center '>
                    <span className='text-sm capitalize mr-2'> ajouter un produit :</span>
                    <input className='w-1/4 h-10 border rounded-md px-4 mr-2' 
                           type="text"
                           placeholder='Ref ...'
                           onKeyUp={(e)=>searchItem(e.target.value)}>

                           </input>
                    <button className='w-24 h-10 bg-green-500 text-sm uppercase'

                            onClick={()=>searchProduct(ref)}>search</button>
                </div>
                <div className='w-full h-0  z-20 relative flex justify-center'>
                    <div className='w-1/2 h-auto bg-gray-200 absolute border border-gray-400'>
                        <ul className='w-full h-auto flex flex-col items-center py-2' id="foundList">
                            {
                                products.length && products.map((product, index)=>
                                    <li className='w-full h-10 hidden flex flex-row border-b border-gray-500 space-x-2 bg-green-200 px-2 items-center' id={`tr${index}`}>
                                        <div className='w-1/6'>
                                            <span className='text-sm '>{product.ref} |</span>
                                        </div>
                                        <div className='flex-1'>
                                            <span className='text-sm '>{product.produit} |</span>
                                        </div>
                                        <div className='w-1/6'>
                                            <span className='text-sm '>{product.volume}-{product.unite} |</span>
                                            </div>
                                        <div className='w-1/6'>
                                            <span className='text-sm font-bold text-red-500'>{product.price}</span>
                                            </div>
                                        <div className=' float-right'>
                                            <button className='w-8 h-8 bg-green-400 text-sm font-bold text-white flex justify-center items-center'
                                                    onClick={()=>addToOrder(product.id, product.produit, product.brand,product.price, product.volume ,product.thumb, 1 ,product.remise)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
          </div>
            
          </div>
          {/** 
              <div className='w-full h-auto flex flex-col mb-2'>
                <div className='w-full h-16 bg-gray-200 border border-gray-300 mb-2 flex flex-row justify-center items-center '>
                    <span className='text-sm capitalize mr-2'> ajouter un produit :</span>
                    <input className='w-1/4 h-10 border rounded-md px-4 mr-2' 
                           type="text"
                           placeholder='Ref ...'
                           onKeyUp={(e)=>getRef(e.target.value)}></input>
                    <button className='w-24 h-10 bg-green-500 text-sm uppercase'
                            onClick={()=>searchProduct(ref)}>search</button>
                </div>
                <div className='w-full h-0 bg-red-200 z-20 relative flex justify-center'>
                    <div className='w-1/3 h-auto bg-blue-400 absolute'>
                        <ul className='w-full h-auto flex flex-col' id="foundList">
                            
                        </ul>
                    </div>
                </div>
          </div>
          **/}
          
          <div className='w-full h-auto flex flex-col border mt-4'>
                            <div className="w-full h-8 flex flex-row bg-gray-600">
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Article</span>
                                </div>
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Qté</span>
                                </div>
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Prix</span>
                                </div>
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Remise</span>
                                </div>
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Total</span>
                                </div>
                            </div>

                            {
                                Object.keys(order).map((key, index)=>
                                
                                <div className="w-full h-auto flex flex-row border-b border-gray-600 py-2 px-2"  id={key} 
                                     //style={{background:(order[key].itemqtt > products[key].instock ) ? "" : "#FFBF00" }}
                                     >
                                    <div className='w-auto h-auto flex items-center justify-center text-center'>
                                       <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 hover:text-white hover:bg-red-500 hover:cursor-pointer" 
                                        onClick={()=>removeProduct(order[key].itemid)}
                                       fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>


                                    <div className="w-1/4 h-8 flex flex-row pl-2">
                                        <span className="text-sm  text-gray-800">{order[key].itembrand} - {order[key].itemname} - {order[key].itemvolum}</span>
                                      
                                    </div>
                                    <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                        <input type="text"
                                                onBlur={(e)=> setqtt(index,order[key].itemid, e.target.value)}
                                                className="text-sm font-bold text-gray-800 border border-yellow-400 w-12 h-8 text-center" placeholder={order[key].itemqtt}/>
                                        
                                    </div>
                                    <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                        <span className="text-sm font-bold text-gray-800">{order[key].itemprice}</span>
                                    </div>
                                    
                                    <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                        <input type="text"
                                               onBlur={(e)=> setRemise(index,order[key].itemid, e.target.value)}
                                               className="text-sm font-bold text-gray-800 border border-yellow-400 w-12 h-8 text-center" placeholder={order[key].itemremise}/>
                                        {/*
                                        <span className="text-sm font-bold text-gray-800">{items[key].itemremise}</span>
                                        */}
                                    </div>
                                    
                                    <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                        <span className="text-sm font-bold text-gray-800">{parseInt(order[key].itemqtt)*parseFloat(order[key].itemprice * ((100 - order[key].itemremise)/100))}</span>
                                    </div>
                                </div>
                                
                            )
                            }
          </div>
          
          <div className='w-full border  mt-2 flex flex-col justify-end items-end  py-2'>
              <div className='w-1/3 h-auto flex flex-row border-gray-600 border px-4'>
                    <div className='w-1/2 mr-2 text-sm font-bold uppercase '>total</div>
                    <div className='text-md font-bold uppercase text-red-500 '>
                        {
                        Object.keys(order).map((key, index)=>{
                                //let p = order[key].itemprice
                                //t = parseFloat(order[key].itemprice).toFixed(2) + 1
                                print(order[key].itemprice, order[key].itemqtt, order[key].itemremise)
                        })
                       
                        }
                        <span>{t}</span>
                    </div>
               </div>
          </div>
          
          <div className='w-full h-24 border bg-gray-200 mt-2 flex flex-row justify-end items-center px-12'>
              <button className='bg-yellow-400 w-24 h-10 rounded-md '
                      onClick={()=>validate()}>save</button>
          </div>
      </div>
  )
}

export default AlterOrder;
