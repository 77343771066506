import React from 'react'
import { Link } from 'react-router-dom'

const Navl1 = () => {
    const openContact = () =>{
        let contactList = document.getElementById("contactList")
        let toplign = document.getElementById("toplign")
        contactList.classList.toggle("show")
        toplign.classList.toggle("border-b")
        
    }
    return (
        <div className="w-full h-auto flex lg:flex-row xs:flex-col xs:px-2 lg:px-12 items-center justify-between border-b border-gray-200 bg-yellow-400">
                <div className="lg:w-auto xs:w-full flex flex-row items-center justify-between px-2 xs:py-2 lg:py-0" id="toplign">
                    <span className="lg:text-sm xs:text-xs capitalize">produits d'esthétique automobile </span>
                    <span className="lg:hidden xs:block cursor-pointer"
                    onClick={()=>openContact()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </span>    
                </div>
                
                <ul className="w-auto h-auto flex-row xs:hidden lg:flex text-sm lg:pt-2 lg:pb-2" id="contactList">
                    
                    <li className="p-2 cursor-pointer flex lg:flex-row xs:flex-col items-center space-x-1 ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    <p className="xs:none lg:flex text-sm">contact@gomispro.com</p></li>
                    
                    <li className="p-2 cursor-pointer flex lg:flex-row xs:flex-col items-center space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="lg:text-md xs:text-xs">7j / 7</p> 
                    </li>

                    <li className="p-2 cursor-pointer flex lg:flex-row xs:flex-col items-center space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p className="lg:text-md xs:text-xs">8h - 17h</p> 
                    </li>
                </ul>
            </div>
    )
}

export default Navl1
