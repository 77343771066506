import React from 'react'
import MyOrders from '../../components/profile/MyOrders'
import UserNav from '../../components/profile/UserNav'

const userorders = () =>{
    return (
        <div>
                <UserNav /> 
                <MyOrders />       
        </div>
    )
}

export default userorders
