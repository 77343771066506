import React from 'react'
import { useParams } from 'react-router'
import Nav from '../components/Nav'
import Cart from '../components/Cart'

export default function Product() {
    
    return (
        <div>
            <Nav />
            <Cart />
        </div>
    )
}