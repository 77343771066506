import React from 'react'
import UserNav from '../../components/profile/UserNav'
const securite = () =>{
    return (
        <div>
            <UserNav />
            securite
        </div>
    )
}

export default securite
