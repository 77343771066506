import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import AlterItem from '../../components/admin/AlterItem'

const updateItem = () =>{
    return (
        <div>
            <AdminNav />
            <AlterItem />
        </div>
    )
}

export default updateItem
