import React from 'react'
import Nav from '../components/Nav'
import Facture from '../components/Facture'
const Facturation =() =>{
    return (
        <div>
            <Nav />
            <Facture />
        </div>
    )
}

export default Facturation
