import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Card from './Card'
import ItemsInCart from '../components/ItemsInCart'
function Cart() {
    const [Sheeping, setSheeping] = useState(5)
    const [Radio, setRadio] = useState(5)
    /*
    let cartItem = JSON.parse(localStorage.getItem('cartitems'))
    const [items, setItems] = useState([])
    useEffect(()=>{
        setItems(cartItem)
    }, [])
    */
   const sheeping = (s) =>{
        localStorage.setItem("sheeping", s)
   }
    const amount = useSelector((state)=> state.totalInCart.total)

    return ( 
        <div className="w-full lg:pl-12 lg:pr-12 flex flex-col  lg:pt-6 xs:mt-20 lg:mt-36">
                <div className="w-full h-auto flex flex-row justify-center py-4">
                    <span className="text-lg font-bold uppercase">panier</span>
                </div>
                {/* */}
                <div className="container mx-auto h-auto flex flex-col lg:pl-4 lg:pr-2 ">
                    
                        {
                         <ItemsInCart  />
                        }       
                   
                </div>
                
                <div className="w-full h-auto flex flex-row justify-end mt-10 border-b-2 border-yellow-400">
                            <div className="w-3/4 h-auto flex flex-col px-2 py-2 hidden">
                                <div className="w-full h-auto flex flex-row border-b border-gray-700"></div>
                            </div>
                            <div className="lg:w-1/4  xs:w-full flex flex-col divide-y-1 bg-gray-100">
                                <div className="w-full h-auto py-2 px-2 ">
                                    <span className="text-md font-bold capitalize border-b border-yellow-300  py-2">Totaux du panier</span>
                                   
                                </div>
                                <div className="w-full h-auto py-2 px-4 flex flex-row justify-between">
                                    <span className="text-sm font-bold capitalize py-2">sous-total</span>
                                    <span className="text-sm font-bold capitalize py-2 flex flex-row">{parseFloat(JSON.parse(localStorage.getItem("totalincart")))}
                                   .DA
                                    </span>  
                                </div>
                                <div className="w-full h-auto py-2 px-2 ">
                                    <span className="text-md font-bold capitalize border-b border-yellow-300  py-2 flex flex-row">frais d'expédition :  
                                      
                                    </span>
                                   
                                </div>
                                <div className="w-full h-auto py-2 px-4 flex flex-col justify-between">
                                    <span className="text-sm font-semibold text-right">yalidine التسليم يتم مع شركة النقل -</span>
                                    <span className="text-sm font-semibold text-right"> يتم الدفع عند التسليم -</span>
                                    <span className="text-sm font-semibold text-right"> سيتم إضافة مبلغ 1.5٪ من إجمالي الفاتورة إلى مبلغ الفاتورة (150 دج مقابل 10000 دج) -</span>
                                  <span className="w-full h-1 bg-green-400 mt-1 mb-1"></span>
                                  <span className="text-sm font-semibold">  la livraison ce fait avec la société de transport yalidine </span>
                                  <span className="text-sm font-semibold"> le paiment ce fait lors de la livraison</span>
                                  <span className="text-sm font-semibold"> une somme de 1.5% du total de la facture serra ajouter au monton de la facture (150 da pour 10 000 da)</span>
                                 

                                 
                                </div>
                                <div className="w-full h-auto py-2 px-4 flex flex-row justify-between bg-yellow-400">
                                    <span className="text-sm font-semibold capitalize py-2">Total</span>  
                                    <span className="text-sm font-semibold capitalize py-2 flex flex-row">
                                      { 
                                            parseFloat(amount)
                                            
                                        }
                                        .DA
                                      </span>  
                                </div>
                            </div>
                            
                </div>
                <div className="w-full h-auto lg:px-8 xs:px-2 lg:py-12 flex lg:flex-row xs:flex-col justify-end space-y-1">
                           <Link to={`/`} className="lg:text-md xs:text-sm lg:font-semibold xs:font-medium  bg-gray-500 text-white px-4 py-2 capitalize rounded-full ml-4 text-center">continuer mes achats</Link>
                           <Link to={`/checkout`} className="lg:text-md xs:text-sm lg:font-bold xs:font-medium bg-yellow-500 text-black hover:bg-black hover:text-white px-4 py-2 capitalize rounded-full  ml-4 text-center">procéder au paiement</Link> 
                </div>
        </div>
    )
}

export default Cart
