import React from 'react'
import ToPrint from './ToPrint'
import ComponentToPrint from './ComponentToPrint'
import MyDocument from './MyDocument'
const Succesfull = () => {
  return (
    <div>
      <MyDocument />
    </div>
  )
}

export default Succesfull

