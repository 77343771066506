import React, {useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router'
import axios from "axios"

const TrackItems = () =>{
    const { id } = useParams();

    const [listorders, setListorders] = useState([])
    const urlAPI = `${process.env.REACT_APP_url}/orders/trackitem.php?idv=${id}`
    useEffect(() => {
        //let i = 0;
        axios.get(urlAPI).then(res => { 
            setListorders(res.data)  
    }).catch(err => {
        console.log(err)
        })
    }, [])

    console.log(listorders)
    //console.log(listorders[0].cart)
    //console.log(typeof(listorders[0].cart))
    
    
  return (
                <div className='w-full h-auto flex flex-col px-12 py-4'>
                        <div className='w-full h-12 px-4 bg-yellow-500 border-yellow-500 flex flex-row items-center justify-center'>
                                        <div className='w-96 h-10 text-center text-xs uppercase font-bold'>N° CMD</div>
                                        <div className='w-96 h-10 text-center text-xs uppercase font-bold'>N° FACTURE</div>
                                        <div className='w-24 h-10 text-center text-xs uppercase font-bold'>QTT</div>
                                        <div className='w-72 h-10 text-center text-xs uppercase font-bold'>DATE</div>
                                        <div className='w-96 h-10 text-center text-xs uppercase font-bold'>PRENOM</div>
                                        <div className='w-96 h-10 text-center text-xs uppercase font-bold'>NOM</div>
                                        <div className='w-96 h-10 text-center text-xs uppercase font-bold'>TEL</div>
                        </div>
                        {
                            Object.keys(listorders).map((key, index)=>
                                <div className='w-full h-12 px-4 py bg-gray-100 border-b mb-1 border-gray-200 flex flex-row items-center'>
                                        <div className='w-96 h-10 text-center'>{listorders[key].ido}</div>
                                        <div className='w-96 h-10 text-center'>{listorders[key].facture}</div>
                                        <div className='w-24 h-10 text-center bg-green-400'>{listorders[key].qtt}</div>
                                        <div className='w-72 h-10 text-center'>{listorders[key].date}</div>
                                        <div className='w-96 h-10 text-center'>{listorders[key].firstname}</div>
                                        <div className='w-96 h-10 text-center'>{listorders[key].lastname}</div>
                                        <div className='w-96 h-10 text-center'>{listorders[key].tel}</div>
                                </div>
                            )
                            
                        }
                </div>
  )
}




export default TrackItems