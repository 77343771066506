import React from 'react'
import { useCookies, withCookies, Cookies } from 'react-cookie';
import { Link, useHistory} from 'react-router-dom';
const UserNav = () =>{

    
    const [cookies, setCookie] = useCookies(['user']);
    const history = useHistory()
    const logout = () => {
        setCookie('state', "false", { path: '/' });
        setCookie('user', "null" , { path: '/' });
        history.push('/')
    }
    let user = cookies.user 

    const openMenu = () =>{
        let menuContainer = document.getElementById("menuContainer")
        menuContainer.classList.toggle("openmenu")
    }   
    return (
        <div className="w-auto h-auto flex flex-col">
            <div className="lg:px-12 lg:py-4 xs:px-2 xs:py-2 flex flex-row justify-between lg:bg-gray-50 z-10 border-red-500 border-b-2">
                    <div className="w-auto h-auto lg:px-4  xs:px-2 py-2">
                            <span className="lg:text-sm xs:text-xs font-bold  capitalize text-gray-700">{user[0].firstname}- {user[0].lastname}</span>
                    </div>

                    <ul className="w-auto h-auto lg:px-12 xs:px-2 lg:flex xs:hidden flex-row">
                        
                        <Link to={'/profile'} className="w-auto h-auto lg:px-4 xs:px-2 py-2 hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >Détails du compte </span>
                        </Link>

                        <Link to={'/securite'} className="w-auto h-auto lg:px-4 xs:px-2 py-2 hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >Sécurité  </span>
                        </Link>

                        <Link to={'/orders'} className="w-auto h-auto lg:px-4 xs:px-2 py-2 hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >commandes </span>
                        </Link>

                        <Link to={'/'} className="w-auto h-auto lg:px-4 xs:px-2 py-2 hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >Boutique </span>
                        </Link>

                        <Link to={'/cart'} className="w-auto h-auto lg:px-4 xs:px-2 py-2 hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >panier </span>
                        </Link>

                        <li className="w-auto h-auto lg:px-4 xs:px-2 py-2 hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" onClick={()=>logout()}>LOGOUT</span>
                        </li>
                    </ul>
                    
                    <div className="w-auto h-auto lg:px-4 lg:hidden xs:flex xs:px-2 py-2" onClick={()=>openMenu()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hover:text-yellow-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                    </div>
            </div>
            <div className="w-full h-1/2 bg-gray-200 hidden absolute top-14" id="menuContainer">
                <ul className="w-auto h-full px-2 flex flex-col justify-center">
                            
                        <Link to={'/profile'} className="w-auto h-auto py-2 px-2 border-gray-300 border-b shadow hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >Détails du compte </span>
                        </Link>

                        <Link to={'/securite'} className="w-auto h-auto py-2 px-2 border-gray-300 border-b  hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >Sécurité  </span>
                        </Link>

                        <Link to={'/orders'} className="w-auto h-auto py-2 px-2 border-gray-300 border-b  hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >commandes </span>
                        </Link>

                        <Link to={'/'} className="w-auto h-auto py-2 px-2 border-gray-300 border-b  hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >Boutique </span>
                        </Link>

                        <Link to={'/cart'} className="w-auto h-auto py-2 px-2 border-gray-300 border-b  hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" >panier </span>
                        </Link>

                        <li className="w-auto h-auto py-2 px-2 border-gray-300 border-b   hover:bg-yellow-400 cursor-pointer rounded-md ">
                            <span className="lg:text-sm xs:text-xs lg:font-bold xs:font-semibold capitalize" onClick={()=>logout()}>LOGOUT</span>
                        </li>
                    </ul>
            </div>
        </div>
    )
}

export default UserNav
