import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import CategoriesList from '../../components/admin/CategoriesList'

const categories = () =>{
    return (
        <div>
                <AdminNav />
                <CategoriesList />
        </div>
    )
}

export default categories
