import React, { useEffect, useState } from 'react'
import { Link, useHistory} from 'react-router-dom';
import ItemsOrder from './ItemsOrder'
import Ppl from './Ppl'
import Paypal from './Paypal'
import axios from 'axios';

const PaymentMode = () =>{

    let totalInCart = JSON.parse(localStorage.getItem("totalincart"))
    let shiping = JSON.parse(localStorage.getItem("sheeping"))
    let ct = JSON.parse(localStorage.getItem("toClient"));
    let cartItems = JSON.parse(localStorage.getItem("cartitems"))
    let c = JSON.parse(localStorage.getItem("client"));

    const history = useHistory()
    const [payment, setPayment] = useState("paypal")

    let vbd = "hidden"
    let paypal = "hidden"
    if(payment === "vbd"){
        vbd = "block"
        paypal = "hidden"
    }
    if(payment === "paypal"){
        vbd = "hidden"
        paypal = "block"
    }

    let tc = {}
    if(Object.keys(ct).length == 0 ){
            tc = c 
    }else{
        tc = ct
    }

    let order = {
        u: JSON.stringify(c), //client
        c: JSON.stringify(cartItems), // products in cart
        ct:JSON.stringify(tc), // send to client
        t:totalInCart, // total cart price
        s:shiping // shiping amount
    }
    
   
    const urlAPI = `${process.env.REACT_APP_url}/orders/neworder.php?o=${JSON.stringify(order)}`


    const transactionSuccess = () =>{
        
    }

    const transactionError = () =>{
        console.log('paypal error')
    }

    const transactionCancled = () =>{
        history.push('/cancelled')
        
    }

    function getData(){

    }
    const validate = () =>{
        axios.get(urlAPI).then((res) => {
            history.push('/Success')
          });
    }
    return (
        <div className="w-full h-auto py-2 flex flex-row justify-center mt-36">
            <div className="lg:w-1/2 xs:full h-auto flex flex-col bg-gray-100 rounded-xl px-4">
            
            <div  className="w-full h-auto py-2 border-b border-gray-200">
                <span className="text-lg font-semibold border-b-2 border-yellow-400 capitalize py-2 pr-10 leading-tight">
                    Votre commande 
                </span>
            </div>
            
            <div className=" w-full h-auto  px-4 py-2  flex flex-col">
                
            
            <div className="w-full h-auto flex flex-col mb-4">
                    <ul className="w-full h-auto flex flex-col border-b border-gray-200">
                            <li className="w-full h-auto flex flex-row justify-between py-2">
                                <span className="text-md font-semibold">produit</span>
                                <span className="text-md font-semibold">sous-total</span>
                            </li>
                        </ul>
            
                <ItemsOrder />
            
            </div>  
            
            <div className="w-full h-auto flex flex-col border-t-2 border-gray-500 ">
                        
                        <div className="w-full h-auto py-2 border-b border-gray-200 flex flex-row justify-between">
                            <span>Sous Total </span>
                            <span>{parseFloat(JSON.parse(totalInCart))}</span>
                        </div>
                        {/*
                        <div className="w-full h-auto py-2 border-b border-gray-200 flex flex-row justify-between">
                            <span>expédition </span>
                            <span>{shiping}</span>
                        </div>
                           
                        <div className="w-full h-auto py-2 border-b border-gray-200 flex flex-row justify-between">
                            <span>Total </span>
                            <span>{ parseFloat(totalInCart)}</span>
                        </div>
             */}
            </div>
            
            
            </div>
            
            <div className="w-full h-auto flex flex-col ">
                    {/*
                    <div className="w-full h-auto flex flex-col">
                            <div className="w-ful h-auto py-2 flex flex-row">
                                <input type="radio" className="mr-2"
                                value="vbd"
                                checked={payment === "vbd"}
                                onChange={(e)=>setPayment(e.target.value)}
                                disabled
                                />
                                <span className="text-sm font-bold">Virement bancaire direct (<span className="text-xs text-gray-400">non disponible actuellement</span>) </span>
                            </div>
                            <div className="w-full h-auto pl-6" className={ vbd }>
                                <button className="w-auto h-auto px-4 py-2 text-sm font-semibold rounded-full bg-yellow-400 hover:bg-black hover:text-white">Virement bancaire</button>
                            </div>
                    </div>
                    
                   
                    <div className="w-ful h-auto py-2 flex flex-row">
                        <input type="radio" className="mr-2"
                        value="paypal"
                        checked={payment === "paypal"}
                        onChange={(e)=>setPayment(e.target.value)}
                        />
                        <span className="text-sm font-bold"> Payer avec un compte PayPal </span>
            
                    </div>
                    */}
                    <div className="w-full h-auto pl-6" >
                            
                            
                              <button className="w-auto h-auto bg-yellow-400 rounded-full px-4 py-2 uppercase text-sm font-bold"
                              onClick={()=>validate()}>
                                    Valider ma commande
                                  </button>  
                            
                            </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentMode
