import React from 'react'
import Cat from '../components/Cat';
import Nav from '../components/Nav';
const categorie = () =>{
    return (
        <div>
            <Nav />
            <Cat />
        </div>
    )
}

export default categorie
