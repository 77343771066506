import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick'

const Card = (props) => {

  

    const products = props.products
    
    return (
        <div className="h-auto w-full flex flex-row flex-wrap lg:py-2 lg:px-4 xs:px-0">
        {/*<Slider {...mainProductsettings} className="w-full h-auto">*/}
        {
              products.length && products.map((product, index) => 
              
              
             <div className="product-card  flex flex-row justify-between">
                 <Link to={`product/${product.idvolume}`} >
                    <div className="product-card flex flex-col hover:shadow-md lg:py-2 xs:mr-2 hover:bg-gray-100 border relative">
                      <div className="w-14 h-16 absolute bg-red-500 top-0 right-1 shadow-md flex items-center justify-center rounded-b-full px-2 z-40"
                            style={{display: product.remise > 0 ? "block" : "none"}}>
                        <span className="text-sm font-bold text-white text-center">- {product.remise} % </span>
                        
                      </div>
                      {/** url(${require("../images/10.png").default}) 
                      <div className="product-card-image mt-2 flex justify-center bg-contain bg-center bg-no-repeat" style={{backgroundImage:`url(${process.env.REACT_APP_url}/product/images/${product.thumb})`}}>
                      
                      </div>
                      */}
                      <img src={`${process.env.REACT_APP_url}/product/images/${product.thumb}`}
                           className="product-card-image" /> 
                      <div className="w-full h-auto flex flex-col py-2 border-t border-gray-200 my-2">
                          <Link to={`brand/${product.brand}`}><span className="text-xs font-semibold mb capitalize lg:px-4 xs:px-2 ">{product.brand} </span></Link>
                          <span className="text-sm font-bold capitalize text-blue-500 lg:px-4 xs:px-2 truncate">{product.produit}</span>
                      </div>
                      
                      <span className="lg:text-md  xs:text-xs hover:text-gray-700 font-bold text-gray-800 px-4 flex flex-row justify-between">
                        <p className="text-red-500 flex-row flex" style={{'text-decoration': product.remise > 0 ? "line-through" : ""}}>{product.price}
                                          -DA
                        </p>
                        <p className="text-red-500  flex-row" style={{display : product.remise > 0 ? "flex" : "none"}}>{product.price * ((100 - product.remise)/100)}
                                        -DA
                        </p>
                      </span>
                      
                      <div className="w-full h-auto pt-2 pb-2 flex flex-row justify-between pl-4 pr-4">
                          <button className="w-8 h-8 p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                          </button>
                          <button className="w-8 h-8 p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                            </svg>
                          </button>
                      </div>
                 </div>
         
              </Link>
             </div>

              
            )
           
        }
       {/* </Slider> */}
    </div>
    );
}

export default Card
