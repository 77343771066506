import React from 'react'
import Nav from '../components/Nav';
import MyAccount from '../components/MyAccount';

export default function Account() {
    return (
        <div className="">
            <Nav />
            <MyAccount />
        </div>
    )
}

//export default account
