import React from 'react'
import { useParams } from 'react-router'
import Details from '../components/Details'
import NewDetails from '../components/NewDetails'
import Nav from '../components/Nav'

export default function Product() {
    
    return (
        <div>
            <Nav />
            <Details />
        </div>
    )
}
