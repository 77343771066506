import React, { useState, useEffect }  from 'react'
import { useCookies, withCookies, Cookies } from 'react-cookie';
import { Link, useHistory} from 'react-router-dom';

const User = () =>{


    const history = useHistory()
    
    const [cookies, setCookie] = useCookies(['user']);

    //cookies.state == "false" ?  history.push('/') :
      //  history.push('/user/dashboard')
    
     
        const [firstname, setFirstName] = useState()
        const [lastName, setName] = useState()
        const [rp, setRP] = useState()
        const [adr, setAdr] = useState()
        const [street, setStreet] = useState()
        const [zip, setZip] = useState()
        const [tel, setTel] = useState()
        const [mail, setMail] = useState()

      let user = cookies.user
       useEffect(()=>{
           if(user[0].firstname != ""){
               setFirstName(user[0].firstname)
           }else{
               setFirstName('')
           }

           if(user[0].lastname != ""){
                setName(user[0].lastname)
            }else{
                setName('')
            }

            if(user[0].rp != ""){
                setRP(user[0].rp)
            }else{
                setRP('')
            }

            if(user[0].zip != ""){
                setZip(user[0].zip)
            }else{
                setZip('')
            }

            if(user[0].ville != ""){
                setStreet(user[0].ville)
            }else{
                setStreet('')
            }

           
           if(user[0].mail != ""){
                setMail(user[0].mail)
            }else{
                setMail('')
            }

            if(user[0].adr != ""){
                setAdr(user[0].adr)
            }else{
                setAdr('')
            }

            if(user[0].mob != ""){
                setTel(user[0].mob)
            }else{
                setTel('')
            }

           


        /**  */
       })
       
       const goToLog = ()=>{
            history.push('/account')
       }

        if(cookies.state != "true"){
            goToLog()
        }

        return (
            
            <div className="w-full  h-auto min-h-screen lg:pt-20  xs:pt-12 flex flex-col items-center">
                <div className="w-full h-auto bg-black flex flex-row lg:px-12 xs:px-2 py-2 justify-between items-center">
                            <span className="text-xs lg:font-bold xs:font-semibold capitalize text-gray-100">
                                     modifier les détails de votre compte 
                            </span>
                            <Link to={'/details'} classsName="w-12 h-auto  ">
                                <span className="lg:text-sm xs:text-xs text-black font-bold px-4 py-2  bg-yellow-400  rounded-full capitalize">modifier</span>
                            </Link>
                    </div>
                <div className="lg:w-1/2 xs:w-full h-auto lg:px-4 xs:px-2 py-2">
                    <span className="text-sm ">
                    À partir du tableau de bord de votre compte, vous pouvez consulter vos commandes récentes, gérer vos adresses de livraison et de facturation et modifier votre mot de passe et les détails de votre compte. 
                    </span>
                </div>
                <div className="lg:w-1/2 xs:w-full h-auto  px-4 py-4 ">    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Nom </span>
                            <input disabled type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={firstname} 
                            onChange={(e)=>setName(e.target.value)}></input>
                        </div>
    
                    </div>
    
                    <div className="w-full h-auto flex flex-col w-full">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">prénom   </span>
                            <input disabled type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={lastName} 
                            onChange={(e)=>setName(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Pays / Région   </span>
                            <input disabled type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={rp} 
                            onChange={(e)=>setRP(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">adresse de rue   </span>
                            <input disabled type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={adr} 
                            onChange={(e)=>setAdr(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Ville   </span>
                            <input disabled type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={street} 
                            onChange={(e)=>setStreet(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Code postal / ZIP  </span>
                            <input disabled type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={zip} 
                            onChange={(e)=>setZip(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">num tel :  </span>
                            <input disabled type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={tel} 
                            onChange={(e)=>setTel(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">e-mail </span>
                            <input disabled type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={mail} 
                            onChange={(e)=>setMail(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    
}

export default User
