import React, {Fragment} from 'react'
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Nav from './components/Nav';
import Routes from './conf/routes';

import './index.css';
import './style.css';
/*
const App = () => {
  
  return (
    <div className="w-full h-48">
      <Nav />
      <AllProd />
    </div>
  )
}
*/

function App(){ 
  return(
   <BrowserRouter>
      <Fragment>
        {/*
                {routes.map( ({ path, component, name}) => {
                    return <Route exact path={path} key={name} component={component} />
                })}
              */}
              <Routes />
      </Fragment>

   </BrowserRouter>
  )
}

export default App

