import React, {Fragment, useState, useEffect } from 'react';
import {helmet } from 'react-helmet';
import {Link, redirect } from "react-router-dom";
import AllProd from '../components/AllProd';
import Nav from '../components/Nav';
import Brands from '../components/Brands';
import BestProduct from '../components/BestProduct';
import SpecialOffer from '../components/SpecialOffer'
import BigBanner from '../components/BigBanner';
import SlideShow from '../components/SlideShow';
const axios = require('axios');


export default function Home() {
   
    return(
        <div className="">
            <Nav />
            <SlideShow />
            <Brands className="mt-24"/>
            <AllProd />
        </div>
            )
}