import React from 'react'
import Nav from '../components/Nav';
import ItemsBrand from '../components/ItemsBrand';
export default function Account() {
    return (
        <div className="">
            <Nav />
            <ItemsBrand />
        </div>
    )
}