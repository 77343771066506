import React from 'react'
import UserNav from '../../components/profile/UserNav'
import UserDtails from '../../components/profile/UserDtails'
const userdetails = () =>{
    return (
        <div>
            <UserNav />
            <UserDtails />
        </div>
    )
}

export default userdetails
