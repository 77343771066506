import React , {useEffect, useState} from 'react'
import { useParams } from 'react-router'
import Dropzone from 'react-dropzone';
import axios from 'axios';
const MainItemImage = (props) =>{
            
            const id = props.id_product 
            
            const [image, setImage] = useState([])
            
            const selected_image = (e)=>{
                let s_img = e.target.files
                setImage(s_img[0].name)
            }
            
            const URL_MAIN_ITEM_IMAGE = `${process.env.REACT_APP_url}/product/updateMainImage.php?id=${id}&image=${image}`

            
            
            const uploadmainImage = (id)=>{
                let main_image_form = document.getElementById("main_image_form")
                main_image_form.submit();
            }
            
    return (
       
       <div className="w-full h-auto py-2 flex flex-col border-2 border-dashed border-gray-700 px-4 rounded-md bg-gray-100">
                    {/** MAIN IMAGE */} 
            <span className="text-xs font-bold uppercase text-red-400 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                image principale
            </span>

            <form method="post" 
                  //onSubmit={(e)=>handleSubmit(e)}
                  encType="multipart/form-data" 
                  action={URL_MAIN_ITEM_IMAGE}  
                  id="main_image_form" 
                  target="votar">
                <input 
                        type="file" 
                        name="image[]"
                        multiple
                        onChange={(e)=>selected_image(e)}
                        className="w-full h-10 py-2 border border-gray-700 pl-8 focus:border-yellow-400 mb-2"></input>

                <div className="w-full h-auto flex flex-row justify-end px-12 py-4">
                                    {/** onClick={()=>additem()} */}
                                    <button type="submit" 
                                            className="w-auto h-auto px-4 py-2 rounded-full bg-yellow-400 text-gray-700 capitalize text-sm font-bold"
                                    //onClick={()=>uploadmainImage(id)}
                                    >modifier</button>
                            </div>
            </form>
              
      
            <iframe name="votar" className="none"></iframe>
     </div>
    )
}

export default MainItemImage
