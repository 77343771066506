import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import Facture from '../../components/admin/Facture'

const newFacture = () =>{
    return (
        <div>
            <AdminNav />
            <Facture />
        </div>
    )
}

export default newFacture
