import React from 'react'
import Addcat from '../../components/admin/Addcat'
import AdminNav from '../../components/admin/AdminNav'

const newcategorie = () =>{
    return (
        <div>
            <AdminNav />
            <Addcat />
        </div>
    )
}

export default newcategorie
