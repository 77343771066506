import React from 'react'
import Nav from '../../components/Nav'
import User from '../../components/profile/User'
import UserNav from '../../components/profile/UserNav'

const Profile = () => {
    return (
        <div>
            
            <UserNav />
            <User />
        </div>
    )
}

export default Profile
