import React from 'react';

function BigBanner() {
    return (
        <div className="big-banner w-full  ">
           
        </div>
    )
}

export default BigBanner
