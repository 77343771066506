import React, { useEffect, useState } from 'react'
const ItemsOrder = () => {
    const cartItems = JSON.parse(localStorage.getItem("cartitems"))
    const [items, setItems] = useState([])

    useEffect(() => {
        setItems(cartItems)
    }, [])

    return (
        <ul className="w-full h-auto flex flex-col">
            {
                Object.keys(items).map((key, index)=>(
                    <li className="w-full h-auto flex flex-row justify-between py-2 border-b border-gray-200">
                        <div className="w-auto h-auto flex flex-row">
                        <span className="text-sm mr-2 font-bold uppercase">{items[key].itembrand}</span>
                        <span className="text-sm mr-2 font-bold uppercase">{items[key].itemname}</span>
                        <span className="text-sm mr-2 font-bold uppercase">* {items[key].itemqtt}</span>
                        </div>
                        <span className="text-sm font-bold uppercase">{parseInt(items[key].itemqtt) * parseFloat(items[key].itemprice)}</span>
                    </li>
                )
                )
            }
        </ul>
    )
}

export default ItemsOrder
