import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import Editebrand from '../../components/admin/EditeBrand'
const editebrand = () =>{
    return (
        <div>
            <AdminNav />
            <Editebrand />
        </div>
    )
}

export default editebrand
