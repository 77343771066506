import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import ListOfItems from '../../components/admin/ListOfItems'
const  items = () =>{
    return (
        <div>
            <AdminNav />
            <ListOfItems />
        </div>
    )
}

export default items
