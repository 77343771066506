import React from 'react'
import Nav from '../components/Nav'
import PaymentMode from '../components/PaymentMode'

export default function payment() {
    return (
        <div>
            <Nav />
            <PaymentMode />            
        </div>
    )
}
