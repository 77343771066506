import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Slider from 'react-slick'

const SmallCard = (props) => {
    let {id } = useParams();

    const settings = {
        infinite:true,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        cssEase: "linear",
        
        responsive: [
            {
              breakpoint: 320,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite:true
              }
            },
            {
                breakpoint: 720,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite:true
                }
              }
            
          ]
    }
    
    const products = props.products
    return (
        <div className="w-auto h-auto flex flex-row flex-nowrap py-2" id="samecat">
            <Slider {...settings} className="w-full h-auto py-2">
            {
                products.length && products.map((product, index)=>
                        <div onClick={() => {window.location.href=`/product/${product.idvolume}`}}  className="w-auto cursor-pointer">
                            <div className="w-auto h-32 flex flex-row shadow-md hover:shadow-xl py-2">
                                <div className="w-1/2 h-full bg-cover bg-center bg-no-repeat" style={{backgroundImage:`url(${process.env.REACT_APP_url}/product/images/${product.thumb})`}}></div>
                                <div className="w-1/2 h-full flex flex-col relative pt-2 px-2">
                                    <span className="uppercase text-xs">{product.brand}</span>
                                    
                                    <div className="w-full overflow-hidden">
                                        <span className="capitalize text-sm font-semibold truncate">{product.produit}</span>
                                    </div>

                                    <div className="flex flex-row w-full text-red-600 py-2">
                                            <span className="text-sm font-bold ">{product.price}</span>
                                            <span className="text-sm font-bold">
                                                -DA
                                            </span>
                                    </div>
                                    
                                </div>
                        </div>
                        </div>
                )
            }
            </Slider>
        </div>
    )
}

export default SmallCard
