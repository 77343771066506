import React from 'react';
import AdminNav from '../../components/admin/AdminNav';
import AlterOrder from '../../components/admin/AlterOrder';

const updateOrder = () =>{
    
  return(
      <div className='w-full h-auto flex flex-col '>
            <AdminNav />
            <div className='w-full h-auto flex flex-col px-12'>
                <AlterOrder />
            </div>
      </div>
  )
}

export default updateOrder;
