import React, {useEffect, useState } from 'react';
import AdminNav from '../../components/admin/AdminNav';
import OrderLogsList from '../../components/admin/OrderLogsList';


const orderLogs = () =>{
   
  return (
    <div className='w-full h-auto flex flex-col '>
        <AdminNav />
       
        <div className='w-full h-auto px-12'>
          <OrderLogsList />
        </div>
    </div>
    
  )
}

export default orderLogs