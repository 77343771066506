import React from 'react'

import MainDetails from './MainDetails'

const Billing = () =>{
    return (
        <div className="w-full h-auto flex flex-col justify-center items-center space-y-4 px-2 mt-32">
        
            <MainDetails />
            {/*** */}
            
            {/*** */}
            
        </div>
    )
}

export default Billing
