import React from 'react'
import Publication from '../../components/admin/Publication'
//import { useParams } from 'react-router'

const pub = () =>{
    

  return (
    <div className=''>
      <Publication />
    </div> 
  )
}

export default pub