import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import TrackItems from '../../components/admin/TrackItems'

const salestrack = () =>{
  return (
    <div>
        <AdminNav />
        <TrackItems />
    </div>
  )
}

export default salestrack