import React from 'react'
import LogIn from './LogIn'
import Register from './Register'
const  MyAccount = () => {
    return (
        <div className="w-full  h-auto flex lg:flex-row xs:flex-col lg:px-12 xs:px-2 mt-36 pt-10 divide-x">
            <LogIn />
            <Register />
        </div>
    )
}

export default MyAccount
