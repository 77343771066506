import React from 'react'
import Nav from '../components/Nav'
import Paypal from '../components/Paypal'

const PayPal = () => {
    return (
        <div className="">
            <Nav />
            <Paypal />
        </div>
    )
}

export default PayPal
