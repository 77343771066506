import React, { useEffect, useState } from 'react'
import jsPDF from 'jspdf';
//import fromHTML from "html2canvas";
import { Canvas } from '@react-pdf/renderer';

const ToPrint = () => {
    let c = JSON.parse(localStorage.getItem("client"));
    let ct = JSON.parse(localStorage.getItem("toClient"));
    
    let cartItems = JSON.parse(localStorage.getItem("cartitems"));

    let TinC = JSON.parse(localStorage.getItem("totalincart"));
    let sheeping = JSON.parse(localStorage.getItem("sheeping"));


    const [Client, setClient] = useState([])
    const [ToClient, setToClient] = useState([])
    const [items, setItems] = useState([])
    const [stt, setstt] = useState()

    useEffect(()=>{
        
        setstt(Object.keys(ToClient).length)
    })

    
    
    if(ToClient.status == false){
        //alert(ToClient.status)
        let sendto = document.getElementById("sendto")
        sendto.classList.toggle("hidden") 
    }
    useEffect(() => {
        setClient(c)
    }, [])

    useEffect(() => {
        setToClient(ct)
    }, [])

    useEffect(() => {
        setItems(cartItems)
    }, [])
/*
   const generatePdf=()=>{
 
        var doc = new jsPDF({
            orientation:'portrait',
            unit:'pt',
            format:'a2'
        }); 
        
        
        doc.html(document.querySelector("#content"), {
          callback: function (doc) {
            doc.save();
          }
        });
        
        alert("FROM TOPRINT")
    }
    */
    return (
        <div className="flex flex-col justify-center w-full bg-blue-400 items-center pt-24" >
            <div className="flex flex-col  bg-white shadow-md xs:px-4 lg:px-12 py-2 border-b-2 border-yellow-400 pt-12" style={{width: "1122px"}} id="content">
                    <div className="w-full h-auto flex flex-row">
                        <div className="w-1/2 h-auto flex flex-col">
                            <div className="lg:w-12 lg:h-12 xs:w-10 xs:h-10 mb-2 lg:ml-4 xs:ml-2">
                                 <img className="lg:h-12 lg:w-12 xs:w-10 xs:h-10" src={require('../images/logo/logo.png').default}/>
                            </div>
                            <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">S.A.R.L GOMIS PRO </span>
                            <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">contact@gomispro.com</span>
                            <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">027 77 29 17 </span>
                            <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">05 59 09 68 35</span>
                        </div>
                        <div className="w-1/2 h-auto flex flex-col">
                        { 
                            
                                <div>
                                        <div className="w-full h-auto flex flex-col justify-center py-2 px-4">
                                                <span className="text-sm font-bold text-gray-800 px-4 py-2 bg-gray-100">Client :</span>
                                                <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">{Client.firstname} - {Client.lastName}</span>
                                                <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">{Client.zip} - {Client.adr} / {Client.rp}</span>
                                                <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">{Client.tel}</span>
                                                <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">{Client.mail}</span>
                                        </div>
                                        {/** SEND TO */}
                                        <div className="w-full h-auto flex flex-col justify-center py-2 px-4 bg-red-300" 
                                        style={{
                                            //if(checked.toString === true){}else{}
                                            display: stt === 0 ? "none" : "flex"
                                            }}>
                                                 <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800 px-4 py-2 bg-gray-100">Facturer pour :</span>
                                                <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">{ToClient.firstname} - {ToClient.lastName}</span>
                                                <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">{ToClient.zip} - {ToClient.adr} / {ToClient.rp}</span>
                                                <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">{ToClient.tel}</span>
                                                <span className="lg:text-sm lg:font-bold xs:text-xs xs:font-semibold text-gray-800">{ToClient.mail}</span>
                                        </div>

                                        
                                        
                                        
                                </div>
                                
                               
                            }
                        </div>
                            
                        
                   
                    </div>
                    <div className="w-full h-auto flex flex-col ">
                            <div className="w-full h-8 flex flex-row bg-gray-600">
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Article</span>
                                </div>
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Qté</span>
                                </div>
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Prix</span>
                                </div>
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Remise</span>
                                </div>
                                <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                    <span className="text-sm font-bold text-white">Total</span>
                                </div>
                            </div>
                                {
                                    Object.keys(items).map((key, index)=>(
                                        <div className="w-full h-auto flex flex-row border-b border-gray-600 py-2">
                                            <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                                <span className="text-sm font-bold text-gray-800">{items[key].itembrand} - {items[key].itemname}</span>
                                                
                                            </div>
                                            <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                                <span className="text-sm font-bold text-gray-800">{items[key].itemqtt} </span>
                                            </div>
                                            <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                                <span className="text-sm font-bold text-gray-800">{items[key].itemprice}</span>
                                            </div>
                                            <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                                <span className="text-sm font-bold text-gray-800">{items[key].itemremise}</span>
                                            </div>
                                            <div className="w-1/4 h-8 flex flex-row items-center justify-center">
                                                <span className="text-sm font-bold text-gray-800">{parseInt(items[key].itemqtt)*parseFloat(items[key].itemprice * ((100 - items[key].itemremise)/100))}</span>
                                            </div>
                                        </div>
                                    )
                                    )
                                }
                        </div>
                        <div className="lg:w-1/4 xs:w-full h-auto flex flex-col border bg-gray-100 border-gray-500 px-2 py-2 mt-4">
                            <div className="w-1/2 h-10 flex flex-row border-b justify-between">
                                <span className="text-sm font-bold text-gray-800">Sous Total :</span>
                                <span className="text-sm font-bold text-gray-800 px-4">{parseFloat(TinC).toFixed(2)}</span>
                            </div>
                            <div className="w-1/2 h-10 flex flex-row border-b justify-between">
                                <span className="text-sm font-bold text-gray-800">Frais de livraison :</span>
                                <span className="text-sm font-bold text-gray-800 px-4">{sheeping}</span>
                            </div>
                            <div className="w-1/2 h-10 flex flex-row justify-between">
                                <span className="text-sm font-bold text-gray-800">Total :</span>
                                <span className="text-sm font-bold text-gray-800 px-4">{(parseFloat(TinC)+parseFloat(sheeping)).toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="w-full h-auto flex flex-col px-4 py-4">
                            <span className="text-xs text-gray-600">
                                RÈserve de propriÈtÈ : IDHEM se rÈserve la propriÈtÈ des
                                marchandises jusqu'au paiement intÈgral de vos factures (Loi
                                N∞80355 du 12 Mai 1980).
                                Un intÈrÍt de retard Ègal ‡ 3 fois le taux d'intÈrÍt lÈgal sera
                                appliquÈ au montant total de la facture initiale lors d'un
                                paiement tardif (Article L441-6 du Code du
                                Commerce). Pour les professionnels, le montant de l'indemnitÈ

                                orfaitaire pour frais de recouvrement prÈvue au douziËme
                                alinÈa du I de l'article L. 441-6 est fixÈ ‡
                                40 euros (Art. D. 441-5 du Code du Commerce) - RÈserve de
                                propriÈtÈ : nous nous rÈservons la propriÈtÈ des marchandises
                                jusqu'au paiement intÈgral des
                                factures (loi n∞80355 du 12 mai 1980). Les Conditions
                                GÈnÈrales de Vente sont disponibles ‡ l'adresse suivante :https:
                                //gomispro.com/
                            </span>
                        </div>
            </div>
                                   
        </div>
        
    )
}

export default ToPrint
