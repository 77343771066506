import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import ListOfItems from '../../components/admin/ListOfItems'
import ListOrders from '../../components/admin/ListOrders'
const allOrdes = () =>{
    return (
        <div>
            <AdminNav />
            <ListOrders />
        </div>
    )
}

export default allOrdes
