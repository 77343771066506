import React from 'react'
import Nav from '../components/Nav';
import Promo from '../components/Promo';
const promotions = () =>{
  return (
    <div>
            <Nav />
           <Promo />
        </div>
  )
}

export default promotions